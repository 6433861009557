@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700&subset=cyrillic,cyrillic-ext');
//font-family: 'Fira Sans', sans-serif;
$font: 'Fira Sans', sans-serif;
$color: #3b3b3b;
$color1: #e84814;
$blue: #005496;
$blue1: #1669aa;
@mixin placeholder {
  &::-webkit-input-placeholder { @content; }
  &:-moz-placeholder           { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }  
}
html,body{
	height: 100%;
	min-height: 100%;
}
.clear:after{
	content: ' ';
	display: block;
	clear: both;
}
h2{
	font-size: 18px;
	color: #000;
}
h3{
	font-size: 16px;
	color: #000;
}
h4{
	font-size: 15px;
	color: #000;
}
h5{
	font-size: 14px;
	color: #000;
}
h6{
	font-size: 12px;
	color: #000;
}
body{
	background: #ededed;
	font-family: $font;
	line-height: 1.2;
	-webkit-appearance: none;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	img{
		max-width: 100%;
		height: auto;
	}
	a[href^=tel]{
	    color: #000;
	}
	a:focus{
		outline: none;
	}
}

.blog_red{
   color: red !important;
}
.blog_yellow{
   color: yellow !important;
}
.blog_green{
   color: green !important;
}
.blog_blue{
   color: blue !important;
}
.blog_purpule{
   color: purpule  !important;
}
.blog_orange{
   color: orange  !important;
}
.blog_brown{
   color: brown   !important;
}

body *{
	outline: none;
}
p{
	margin: 0;
}
#wrapper{
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	font-family: $font;
	width: 100%;
	.header-top{
		display: flex;
	    width: 100%;
	    justify-content: space-between;
	    background: #fff;
	    padding: 9px 35px 9px 25px;
	    box-sizing: border-box;
	    position: relative;
	    z-index: 2;
	    .left_block_header{
	    	display: flex;
	    	align-items: center;
	    }
	    .right_block_header{
	    	display: flex;
	    	align-items: center;
	    	.wr_bl{
	    		display: flex;
	    		align-items: center;
	    		p{
	    			margin: 0;
	    			font-size: 14px;
	    		}
	    		a{
	    			position: relative;
	    			display: inline-block;
	    			text-align: center;
	    			color: #000;
	    			text-decoration: none;
	    				div{
	    					display: inline-block;
	    					position: relative;
	    					min-height: 27px;
	    					.icon_count{
	    						position: absolute;
	    						background: #f24841;
	    						color: #fff;
	    						top: -7px;
	    						line-height: 1;
	    						right: -10px;
	    						border-radius: 50%;
	    						width: 15px;
	    						height: 15px;
	    						font-size: 10px;
	    						display: flex;
	    						align-items: center;
	    						justify-content: center;
	    				}
	    				span#wish_icon {
	    				    right: -6px;
	    				}

	    			}
	    		}
	    		.wish_and_comp{
	    			display: flex;
	    			align-items: flex-end;
	    			a:nth-of-type(1){
	    				padding-right: 35px;
	    			}
	    		}
	    		.login_c{
	    			padding-left: 50px;
	    		}
	    		#cart{
	    			padding-left: 35px;
	    			margin-bottom: 0;
	    			padding-right: 25px;
	    			&:hover > .dropdown-menu{
	    				display: block;
	    			}
	    		}
	    	}
	    }
	}
}
#logo{
	margin-right: 50px;
}
.telephones{
	font-size: 15px;
	text-transform: uppercase;
	a{
		font-size: 15px;
		color: #000;
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}
	.telephone_1,.telephone_2{
		display: flex;
		padding: 5px 0;
		.telephone_1_title,.telephone_2_title{
			min-width: 70px;
			text-align: right;
			color: #000;
			line-height: 1.2;
		}
		.pad{
			padding-left: 50px;
			background: url('/catalog/view/theme/default/img/Opta.png') no-repeat;
			background-position: 27px;
			.click_down{
				display: inline-block;
				padding: 0 10px;
				cursor: pointer;
				img{
					transform: rotate(-90deg);
					transition: all .1s ease;
				}
			}
			.telephone_1_drop,.telephone_2_drop{
				position: absolute;
				padding: 10px 15px;
				background: #fff;
				box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
				display: none;
				z-index: 1;
				p{
					border: none;
					font-size: 12px;
					text-transform: uppercase;
					width: 100%;
					padding: 8px 0;
					background: $color1;
					border: 2px solid $color1;
					color: #fff;
					cursor: pointer;
					transition: all .5s ease;
					text-align: center;

					&:hover{
						background: #393939;
					}
				}
				ul{
					padding-left: 20px;
				}
				li a:hover{
                  text-decoration: none;
                  color: $color1;
				}
			}
			&:hover{
				.telephone_1_drop,.telephone_2_drop{
					display: block;
				}
				.click_down{
					img{
						transform: rotate(0);
					}
				}
			}

		}
	}
}
#block_menu{
	display: flex;
	width: 100%;
	background: $blue url('/catalog/view/theme/default/img/sherlock.png') no-repeat;
	position: relative;
	z-index: 1;
	#menu{
        max-width: 753px;
		width: 100%;
		ul{
			list-style-type: none;
		}
		& >	ul{
			& > li a{
				font-weight: 600;
			}
		}
      .menu{
  	    display: flex;
  	    align-items: center;
  	    margin: 0;
  	    justify-content: space-between;
  	    li{
  	    	position: relative;
  	    	a{
  	    		color: #fff;
  	    		line-height: 1.5;
  	    		text-transform: uppercase;
  	    		text-decoration: none;
  	    		font-size: 14px;
  	    		padding: 19px 20px 20px 15px;
  	    		display: inline-block;
  	    		img{
  	    			margin-left: 10px;
  	    			vertical-align: middle;
  	    			transform:rotate(-90deg);
  	    			transition:all .1s ease;
  	    			margin-bottom: 2px;
  	    		}
  	    	}
  	    	&:hover{
  	    		background: #014478;
  	    		box-shadow: 0 1px 2px 1px rgba(1,68,120,.75);
  	    		.menu_drop{
  	    			display: block;
  	    		}
  	    		a img{
  	    			transform:rotate(0);
  	    		}
  	    	}
  	    	.menu_drop{
  	    		position: absolute;
  	    		background: #014478;
  	    		top: 60px;
  	    		left: 0;
  	    		min-width: 215px;
  	    		box-shadow: 0px 2px 6px 0px rgba(57, 57, 57,0.75);
  	    		max-height: 400px;
  	    		overflow: auto;
  	    		ul{
  	    			padding-left: 0;
  	    			li{
  	    				padding: 0;
  	    				// padding: 9px 0;
  	    				transition: all .5s ease;
  	    				a{
  	    					color: #fff;
  	    					line-height: 1.5;
  	    					text-transform: none;
  	    					padding: 14px 20px 14px 15px;
  	    					display: inline-block;
  	    					width: 100%;
  	    				}
  	    				&:hover{
  	    					box-shadow: none;
  	    					background: $color1;
  	    				}
  	    			}

  	    		}

  	    	}
  	    }
  	    .menu_drop{
  	    	display: none;
  	    }
      }
	}
	#search{
		background: $blue1;
	    display: flex;
	    align-items: center;
	    padding: 0 15px;
	    width: 100%;
	    max-width: 324px;
	    box-sizing: border-box;
	    label{
	    	width: 100%;
	    	display: block;
	    	max-width: inherit;
	    	margin-bottom: 0;
	    	font-weight: 400;
	    }
	    input{
	    	background: transparent;
	    	color: #fff;
	    	line-height: 1;
	    	border: none;
	    	outline: none;
	    	font-size: 16px;
	    	padding-left: 10px;
	    	box-sizing: border-box;
	    	width: calc(100% - 36px);
	    	@include placeholder{
              color: #fff;
              font-size: 16px;
	    	}
	    }
	    button{
	    	background: no-repeat;
	    	border: none;
	    	cursor: pointer;
	    	outline: none;
	    }
	}
	&:hover{
		z-index: 3;
	}
}
#price_click{
	width: 100%;
	max-width: 126px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	a{
		color: #fff;
	    font-size: 14px;
	    text-decoration: none;
	    text-transform: uppercase;
	    display: inline-block;
	    width: 100%;
	    text-align: center;
	    padding: 22px 0;
	    box-sizing: border-box;
		img{
			display: inline-block;
			margin-left: 10px;
			transform:rotate(-90deg);
			transition:all .1s ease;
		}
	}
	.price_click_drop{
		position: absolute;
		left: 0;
		right: 0;
		top: 60px;
		background: #014478;
		box-shadow: 0px 2px 6px 0px rgba(57, 57, 57,0.75);
		display: none;
		transition: all .5s ease;
		a{
			font-size: 12px;
			padding: 10px 0;
			display: block;
			transition: all .5s ease;
			&:hover{
				background: #393939;
			}
		}
	}
	&:hover{
		.title{
			opacity: 1;
			visibility: visible;
			transition: .2s ease;
			transition-delay: 0.5s;
		}
	}
	.title{
		position: absolute;
		background: #404040;
		padding: 10px 10px;
		min-width: 172px;
		font-size: 12px;
		text-align: center;
		color: #fff;
		top: -22px;
		opacity: 0;
		visibility: hidden;
		right: -10%;
		font-size: 12px;
		line-height: 1;
		&:after{
			content: ' ';
		    width: 0;
		    height: 0;
		    top: 32px;
		    position: absolute;
		    border-left: 8px solid transparent;
		    border-right: 8px solid transparent;
		    border-top: 8px solid #404040;
		    left: calc(50% - 4px);
		}
	}
	&.act{
		img{
			transform:rotate(0);
		}
	}
}

// swiper=-=-=

    .swiper-container {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    #rev_slider_1_1_wrapper{
    	margin-top: 28px !important;
    	box-shadow: 0 3px 5px 0px rgba(162, 162, 162,0.5) !important;
    	position: relative;
    	z-index: 0;
    	position: relative;
    	z-index: 0;
    	.bullet {
    	    border-radius: 0;
    	    width: 11px;
    	    height: 11px;
    	    background: #fff;
    	    opacity: 1;
    	    margin: 0 6px;
    	}
    	.bullet.selected {
    	    outline:4px solid $blue;
    	}
    	.tp-leftarrow,.tp-rightarrow{
    		display: none;
    	}
    	.tp-bullets.simplebullets.round.hidebullets{
            opacity: 1;
    	}
    	.tp-bannertimer{
    		display: none;
    	}
    }
    .tp-bannershadow.tp-shadow2{
    	background: none !important;
    }

// Преимущества=-=-=-

.advantages{
	background: #fff;
	display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-wrap:wrap;
    z-index: -1;
    .advantages_child {
        width: 25%;
        display: flex;
        align-items: flex-start;
        border-left: 1px solid #ddd;
        padding-left: 30px;
        padding-top: 25px;
        min-height: 92px;
        box-sizing: border-box;
        div:nth-of-type(1){
        	margin-right: 20px;
        }
        div:nth-of-type(2){
        	p{
        		margin: 0;
        		font-size: 14px;
        		font-weight: 600;
        		line-height: 1;
        		text-transform: uppercase;
        		color: $color;
        		margin-bottom: 10px;
        	}
        	p + p{
        		font-weight: 400;
        		font-size: 10px;
        		line-height: 1.5;
        		span{
        			display: block;
        		}
        	}
        }
    }
}


// specials=-=-=
#special{
	.time{
		.is-countdown{
			.countdown-row {
				display: flex;
			    justify-content: flex-end;
			}
			.countdown-section{
				display: inline-block;
				position: relative;
				&:before{
                   content: ':';
				}
				.countdown-period{
					display: none;
				}
			}
			.countdown-section:nth-of-type(1):before{
                display: none;
			}
		}
	}
}
#special .btn_sp button + button{
   display: none;
}
.block_p{
	margin-top: 100px;
	.swiper-container {
	    padding-bottom: 10px;
	}
	& > .title{
		position: relative;
		font-size: 22px;
		color: #000;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 20px;
		border-bottom: 2px solid #1569a9;
		    padding-bottom: 15px;
		.swiper-button-next{
		    background-image: url('/catalog/view/theme/default/img/arrow_slider.png');
		    background-size: contain;
		    width: 15px;
		    height: 24px;
		    transform: rotate(180deg);
		    right: 0;
		    margin-top: 0;
		    top: 0;
		}
		.swiper-button-prev{
			background-image: url('/catalog/view/theme/default/img/arrow_slider.png');
			background-size: contain;
			width: 15px;
			height: 24px;
			left: inherit;
			right: 55px;
			margin-top: 0;
			top: 0;
		}
	}
	.special_block{
		padding: 25px 22px;
		box-sizing: border-box;
		box-shadow: 0 3px 7px 0px rgba(0,0,0,0.14);
		transition:all .2s ease-in;
        .title{
        	text-align: left;
        	min-height: 50px;
        	a{
    	       	font-weight: 600;
    	       	font-size: 17px;
    	       	color: #000;
    	       	line-height: 1.5;
    	       	text-decoration: none;
    	       	transition:all .3s ease-in;
    	       	&:hover{
    	       		color: $color1;
    	       	}
    	    }
        }
        .sku{
        	text-align: left;
        	font-weight: 600;
        	font-size: 17px;
        	color: #000;
        	line-height: 1.5;
        	text-transform: uppercase;
        }
        .img{
        	position: relative;
        	margin-top: 10px;
        	.stiker_wr{
        		position: absolute;
        		left: 0;
        		top: 10px;
        	}
        	img{
        		max-width: 100%;
        		height: auto;
        	}
        }
        .description{
        	font-size: 12px;
        	line-height: 1.5;
        	min-height: 55px;
        	max-height: 55px;
        	overflow: hidden;
        	margin-bottom: 10px;
        	margin-top: 10px;
        }
        .price_wrap{
        	display: flex;
        	align-items: flex-end;
        	&>div{
        		width: 50%;
        		text-align: right;
        	}
        	.wish_comp{
        		display: flex;
        		justify-content: flex-end;
        		display: flex;
        		justify-content: flex-end;
        		align-items: center;
        		cursor: pointer;
        		span{
        			display: inline-block;
        		}
        		span:nth-of-type(2){
        			margin-left: 15px;
        		}
        	}
        	.price{
        		font-size: 12px;
        		font-weight: 500;
        		color: #dc0202;
        		text-align: left;
        		min-height: 44px;
        		display: flex;
        		flex-direction: column;
        		align-items: flex-start;
        		justify-content: flex-end;
        		p{
        			font-weight: 700;
        			font-size: 21px;
        			line-height: 1.5;
        			margin: 0;
        			color: #000;
        		}
        		.cross{
        			padding: 0 3px;
        			position: relative;
        			display: inline-block;
        			&:after{
        				content: ' ';
        				display: block;
        				height: 2px;
        				width: 100%;
        				background: #dc0202;
        				opacity: .3;
        				top: calc(50% - 1px);
        				position: absolute;
        			}
        		}
        	}
        	.time{
        		p:nth-of-type(1){
        			font-size: 10px;
        			color: $color;
        			line-height: 1.5;
        		}
        		p:nth-of-type(2){
        			font-size: 17px;
        			color: $color1;
        			font-weight: 600;
        			line-height: 1.5;
        		}
        	}
        }
        .btn_sp{
        	margin-top: 10px;
        	button{
        		border: none;
        		font-size: 16px;
        		text-transform: uppercase;
        		width: 100%;
        		padding: 8px 0;
        		background: $color1;
        		border: 2px solid $color1;
        		color: #fff;
        		cursor: pointer;
        		transition: all .5s ease;
        		position: relative;
        		z-index: 1;
        		&:before {
				    content: ' ';
				    position: absolute;
				    width: 100%;
				    left: 0;
				    right: 0;
				    background: #fff;
				    height: 0;
				    top: 0;
				    z-index: -1;
				    transition:all 0.3s ease;
				}
				&:hover:before{
					height: 100%;
				}
				&:hover{
					color: $color1;
				}
        	}
        }
        &:hover{
        	box-shadow: 0 10px 6px -6px #777;
        }
	}
}
#new_product_1{
	clear: both;
	.btn_sp button + button{
		display: none;
	}
}
#new_product,.category,#related{

	.special_block{
		.btn_sp{
			margin-top: 10px;
			display: flex;
			    justify-content: space-between;
			// button{
			// 	max-width: 180px;
			// }
			button + button{
				max-width: 42px;
				width: 100%;
				height: 38px;
				border: none;
				// background: #00ad5d url(/catalog/view/theme/default/img/OneClick.svg) no-repeat;
				// background-size: 25px;
				// background-position: center;
				border: 2px solid #00ad5d;
				background: #00ad5d;
				position: relative;
				margin-left: 10px;
				position: relative;
				z-index: 1;
				img + img{
					display: none;
				}
        		&:before {
				    content: ' ';
				    position: absolute;
				    width: 100%;
				    left: 0;
				    right: 0;
				    background: #fff;
				    height: 0;
				    top: 0;
				    z-index: -1;
				    transition:all 0.3s ease;
				}
				&:hover:before{
					height: 100%;
				}
				&:hover{
					// background: #0c8b50 url(/catalog/view/theme/default/img/OneClick.svg) no-repeat;
					// background-size: 25px;
					// background-position: center;
					.one_title{
							opacity: 1;
							visibility: visible;
							transition: .2s ease;
							transition-delay: 0.5s;
						}
						img{
							display: none;
						}
						img + img{
							display: inline;
						}
				} 
				.one_title{
					position: absolute;
				    background: #404040;
				    padding: 10px 10px;
				    min-width: 136px;
				    font-size: 12px;
				    text-align: center;
				    color: #fff;
				    top: -42px;
				    font-size: 12px;
				    line-height: 1;
				    text-transform: none;
				    font-weight: 400;
				    left: -56px;
				    opacity:0;
				    visibility: hidden;
				    &:after{
				    	content: ' ';
				    	width: 0;
				    	height: 0;
				    	top: 32px;
				    	position: absolute;
				    	border-left: 8px solid transparent;
				    	border-right: 8px solid transparent;
				    	border-top: 8px solid #404040;
				    	left: calc(50% - 4px);
				    }
				}
				img{
					width: 25px;
				}
			}
		}
	}
   .special_block.list .btn_sp button:nth-of-type(1){
		  	max-width: inherit;
		  }
} 

.banner_1,.banner_second{
	margin-top: 70px !important;
}
// news-=-=-

#new_blog,#blog_popular{
	.new_blog_wr{
		display: flex;
		overflow: hidden;
		.blog_img{
			font-size: 0;
			img{
				min-width: 233px;
			}
		}
		.blog_desc{
			padding: 20px;
			.blog_title{
				display: flex;
				justify-content: space-between;
				div:nth-of-type(1){
					color: $blue;
					font-size: 13px;
					font-weight: 600;
					text-transform: uppercase;
				}
				.date{
					font-weight: 500;
					font-size: 13px;
					color: #000;

				}
			}
			.new_title{
				font-size: 17px;
				font-weight: 700;
				line-height: 1.2;
				margin: 20px 0;
				text-align: left;
			}
			.descr{
				font-size: 13px;
				font-weight: 400;
				line-height: 1.2;
				text-align: left;
				min-height: 60px;
			}
			.blog_btn{
				text-align: left;
				margin-top: 15px;
				a{
					color: $color1;
					font-size: 13px;
					text-decoration: none;
					transition: all .5s ease;
					&:hover{
						color: #393939;
					}
				}

			}
		}
	}
	.swiper-container {
	    padding-bottom: 0;
	}
}
.manufacturer{
	background: $blue;
	box-sizing: border-box;
	margin-top: 70px;
	margin-bottom: 70px;
	.title{
		font-size: 19px;
		font-weight: 600;
		color: #fff;
		text-transform: uppercase;
		padding-top: 50px;
		text-align: center;
	}
	.swiper-container{
		padding: 60px 0;
	}
	.swiper-slide{
		background: transparent;
		img{
			max-width: 100%;
			height: auto;
		}
	}
	.swiper-button-next {
	    background-image: url('/catalog/view/theme/default/img/manuf/manuf_arrow.png');
	    width: 10px;
	    height: 16px;
	    right: -50px;
	    left: auto;
	    background-size: 10px 16px;
	    margin-top: 0;
	    top: calc(50% - 8px)
	}
	.swiper-button-prev {
	    background-image: url('/catalog/view/theme/default/img/manuf/manuf_arrow.png');
	    left: -50px;
	    right: auto;
	    width: 10px;
	    height: 16px;
	    background-size: 10px 16px;
	    transform: rotate(180deg);
	    margin-top: 0;
	    top: calc(50% - 8px)
	}
	.wr_manuf{
		position: relative;
		margin: 0 90px;
	}
}

.welcome{
	margin-bottom: 70px;
	position: relative;
	z-index: 10;
	p{
		font-size: 13px;
		    line-height: 18px;
	}
	li{
		line-height: 1.5;
	}
	.title_welcome{
		font-weight: 600;
		margin-bottom: 20px;
	}
	a{
		transition:all .3s ease-in;
	}
	a:hover{
       text-decoration: none;
       p,span{
       	color: $blue1 !important;
       }
	}
}
#subscribe{
	background: #fff;
	padding: 22px 10px;
	.subscribe{
		max-width: 716px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		align-items: baseline;
	}
	.subscribe_title{
		font-size: 17px;
		color: $color;
		display: flex;
		align-items: center;
	}
	form{
		max-width: 460px;
		width: 100%;
	}
	.wrap_form{
		width: 100%;
		justify-content: space-between;
		    display: flex;
		label{
			width: 100%;
			max-width: 303px;
			display: inline-block;
			box-sizing: border-box;
			position: relative;
			input{
		    	@include placeholder{
	              color: #cdcdcd;
	              font-size: 15px;
	              font-weight: 400;
		    	}
		    	padding: 0px 15px;
		    	line-height: 36px;
		    	width: 100%;
		    	box-sizing: border-box;
		    	font-size: 15px;
		    	outline: 0;
		    	-webkit-box-sizing: border-box;
		    	box-sizing: border-box;
		    	font-size: 15px;
		    	font-weight: 400;
		    	border: 1px solid #cdcdcd;
			}
			.err{
				// position: absolute;
			 //    bottom: -20px;
			    color: red;
			    font-weight: 400;
			    margin-top: 3px;
			    font-size: 12px;
			    position: absolute;
			}
		}

    	.subscribe_btn{
    		border: none;
    		font-size: 16px;
			width: 100%;
		    max-width: 138px;
		    height: 38px;
    		background: $color1;
    		border: 2px solid $color1;
    		color: #fff;
    		cursor: pointer;
    		transition: all .3s ease-in;
    		position: relative;
    		z-index: 1;
    		&:after {
			    content: ' ';
			    position: absolute;
			    width: 100%;
			    left: 0;
			    right: 0;
			    background: #fff;
			    height: 0;
			    top: 0;
			    z-index: -1;
			    transition:all 0.3s ease-in;
			}
			&:hover:after{
				height: 100%;
			}
			&:hover{
				color: $color1;
			}
    	}

	}
}


// попап входа -=-=-
.wel_modal{
	position: relative;
	z-index: 9992;
}
#header_load.act{
	opacity: 1;
	visibility: visible;
}
#welcome_load{
	position: relative;
	#header_load{
		position: absolute;
		width: 300px;
		right: 0;
		opacity: 0;
		top: 30px;
		transition: all .3s ease;
		visibility: hidden;
		box-shadow: 0 2px 2px 1px rgba(134,134,134,.5);
		background: #fff;
		z-index: 5;
		.title_n{
			padding: 25px 0 0 0;
			margin: 0 25px 0 25px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #e0e0e0;
			.item11{
				text-align: left;
				margin: 0;
				span{
					font-size: 14px;
					text-transform: uppercase;
					display: inline-block;
					border-bottom: 3px solid $color;
					line-height: 1.5;
					margin-bottom: -2px;
				}
			}
			.btn_close{
	            padding: 5px 5px;
	            cursor: pointer;
			}
		}
		.login_wr{
			padding: 0 25px;
			box-sizing: border-box;
			.login-form{
				text-align: left;
				input{
					width: 100%;
					border-radius: 0;
					font-size: 14px;
				}
				.color{
					color: red;
				}
			.title_pop{
				font-size: 14px;
				color: #000;
				line-height: 1.5;
			}
			.input-email{
				padding-top: 15px;
			}
			.input-password{
				padding: 10px 0;
			}
		}
		.buttons{
			p{
				margin-bottom: 15px;
				font-size: 14px;
				color: #000;
				line-height: 1.5;
				a{
					border-bottom: 2px dashed $color;
					text-decoration: none;
					transition: all .5s ease;
					color: $color;
					&:hover{
						border-bottom: 2px solid $color;
					}
				}
			}
		}
		}
		.razd{
			width: 100%;
			height: 1px;
			background: #e0e0e0;
			margin: 40px 0;
			position: relative;
			span{
				position: absolute;
				width: 35px;
				height: 35px;
				left: calc(50% - 17.5px);
				top: calc(50% - 17.5px);
				border: 1px solid #e0e0e0;
				border-radius: 20px;
				background: #fff;
				align-items: center;
				display: flex;
				font-size: 14px;
				color: #000;
				justify-content: center;
			}
		}
		.main_social{
			text-align: center;
			p{
				font-size: 14px;
				color:#000;
				line-height: 1.5;
				padding-bottom: 20px;
			}
			.reg span{
				color: #000;
				font-size: 14px;
				padding: 0 3px;
			}
			.zareg{
				color: $color;
				transition:all .3s ease;
				&:hover{
					color: #000;
				}
			}
		.soc_link{
			padding-bottom: 20px;
		}
		.reg_main{
			padding: 5px 0;
			border-top: 1px solid #e0e0e0;
		}
		}
		&.act{
          opacity: 1;
          visibility: visible;
		}
		.btn_all{
			font-size: 16px;
			text-transform: uppercase;
			width: 100%;
			padding: 10px 0;
			background: $color1;
			border: 2px solid $color1;
			color: #fff;
			cursor: pointer;
			transition: all .5s ease;
			position: relative;
			z-index: 1;
			margin-bottom: 10px;
			border-radius: 0;
			&:before {
			    content: ' ';
			    position: absolute;
			    width: 100%;
			    left: 0;
			    right: 0;
			    background: #fff;
			    height: 0;
			    top: 0;
			    z-index: -1;
			    transition:all 0.3s ease;
			}
			&:hover{
				color: $color1;
			}
			&:hover:before{
				height: 100%;
			}
		}
	}
	 img {
	    padding-right: 10px;
	}
	.btn_close{
		position: absolute;
		right: 20px;
		top: 20px;
		cursor: pointer;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		z-index: 5;
		transition: all .5s ease;
		 span {
		    width: 70%;
		    height: 2px;
		    background: #e84815;
		    display: block;
		    position: absolute;
		}
		.line1 {
		    top: 48%;
		    left: 3px;
		    -moz-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		    -webkit-transform: rotate(45deg);
		    -o-transform: rotate(45deg);
		    transform: rotate(45deg);
		}
		.line2 {
		    top: 48%;
		    left: 3px;
		    -moz-transform: rotate(315deg);
		    -ms-transform: rotate(315deg);
		    -webkit-transform: rotate(315deg);
		    -o-transform: rotate(315deg);
		    transform: rotate(315deg);
		}
		&:hover{
			transform: rotate(180deg);
		}
	}
}
// конец-=-=-=

#background_site{
	position: fixed;
	background: rgba(0,0,0,0.3);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	display: none;
}
#search{
   position: relative;
   .live-search{
   	top: 60px;
   	margin-top: 0;
   	display: block;
    position: absolute;
    width: 100%;
    left: inherit;
    min-width: 320px;
    right: 0;
    background: $blue;
    color: #fff;
    max-height: 300px;
    overflow: auto;
    ul {
       	list-style-type: none;
       	margin:0px;
       	padding:0px;
       	li{
       		padding: 10px;
       		position: relative;
       		transition: all .5s ease;
       		border-bottom: 1px solid #fff;
       		a{
       			display: flex;
   			    justify-content: space-between;
   			    align-items: center;
   			    color: #fff;
   			    text-decoration: none;
   			    font-size: 14px;
   			    .product-image{
   			    	width: 50px;
   			    }
   			    .product-image img{
   			    	height: 40px;
   			    	border: 1px solid #fff;
   			    	display: block;
   			    	min-width: 40px;
   			    }
   			    .product-name{
   			    	padding: 0 10px;
   			    }
   			    .img_name{
   			    	display: flex;
   			    	align-items:center;
   			    	justify-content: space-between;
   			    }
       		}
       		&:hover{
       			background: $color;
       		}
       		.price{
       			display: block;
       		}
       	}
       }
   }
   .result-text{
   	text-align: center;
   	position: relative;
   	&:after{
   		content: ' ';
   		display: block;
   		position: absolute;
   		left: 0;
   		right: 0;
   		top: 0;
   		height: 1px;
   		background: #fff;
   	}

 	a{
 		border: none;
 		font-size: 16px;
  	 	display: inline-block;
	    padding: 8px 25px;
 		background: $color1;
 		border: 2px solid $color1;
 		color: #fff;
 		cursor: pointer;
 		transition: all .5s ease;
 		position: relative;
 		z-index: 1;
 		margin: 10px 0;
 		&:after {
		    content: ' ';
		    position: absolute;
		    width: 100%;
		    left: 0;
		    right: 0;
		    background: #fff;
		    height: 0;
		    top: 0;
		    z-index: -1;
		    transition:all 0.3s ease;
		}
		&:hover{
			color:$color1;
		}
		&:hover:after{
			height: 100%;
		}
		&:hover,&:focus{
			text-decoration: none;
		}
 	}


   }
}

#arrow_top {
    background:#9ab8cf url(/catalog/view/theme/default/img/top_up.png) no-repeat center !important;
    height: 53px !important;
    width: 53px !important;
    transition: all .5s ease;
    &:hover{
    	background:#2b79c2 url(/catalog/view/theme/default/img/top_up_1.png) no-repeat center !important;
    }
}
footer{
    background: $blue url(/catalog/view/theme/default/img/sherlock_footer.png) no-repeat;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .wrap_footer{
    	display: flex;
    	padding-top: 44px;
    	.wr_foot{
    		width: calc(20% - 10px);
    		margin-right: 10px;
    		.footer_title{
    			font-size: 17px;
    			color: #fff;
    			font-weight: 600;
    			text-transform: uppercase;
    			line-height: 1.2;
    			margin-bottom: 30px;
    		}
    		li{
    			color: #fff;
    			line-height: 18px;
    			a{
	    			font-weight: 400;
	    			font-size: 13px;
	    			color: #fff;
	    			text-decoration: none;
	    			line-height: 18px;
	    			transition:all .5s ease-in;
	    			position: relative;
	    			&:before{
	    				content: '';
	    				position: absolute;
	    				transition: transform .5s ease;
	    				left: 0;
    				    bottom: -1px;
    				    width: 100%;
    				    height: 2px;
    				    background: #fff;
    				    transform: scaleX(0);
	    			}
	    			&:after{
	    				content: '';
    				    position: absolute;
    				    transition: transform .5s ease;
	    			}
	    			&:hover:before {
	    			    transform: scaleX(1);
	    			}
	    		}
    		} 
    	}
    	ul{
    		list-style-type: none;
    		padding-left: 0;
    		margin-bottom: 0;
    	}
    	.town{
    		margin-bottom: 20px;
    	}
    	.powered_wr{
    		color: #fff;
    		font-size: 13px;
    		font-weight: 400;
    		margin-top: 40px;
    		margin-bottom: 20px;
    	}
    	.soc_icon{
    		a{
    			display: inline-block;
    			margin-right: 15px;
    			i{
    				color: #fff;
    				font-size: 24px;
    				transition: all .3s ease;
    				&:hover{
    					transform:scale(1.1);
    				}
    			}
    		}
    	}
    }
    .footer_bottom{
    	display: flex;
    	padding: 10px;
    	    margin: 0;
    	a{
    		display: inline-block;
    		margin-right: 40px;
    		color: #fff;
    		cursor: pointer;
    		&:hover{
    			text-decoration: none;
    		}
    		img{
    			transition:all .3s ease-in;
    		}
    		&:hover img{
    			transform: scale(1.1);
    		}
    	}
    }
}



#header_scroll_fix{
	display: none !important;
}


#cart >.dropdown-menu {
    border-radius: 0;
    border: 1px solid #003762;
    min-width: 400px;
    padding: 0;
    .table-striped {
    	margin-bottom: 0;
    	&>tbody>tr {
    	        background-color: $blue;
    	        td{
    	        	vertical-align: middle;
    	        	color: #fff;
    	        	border-top: none;
    	        	border-bottom: 1px solid #fff;
    	        	a{
    	        		color: #fff !important;
    	        		transition: all .3s ease-in;
    	        		&:hover{
    	        			color: $color1 !important;
    	        		}
    	        	}
    	        	.img-thumbnail{
    	        		border-radius: 0;
    	        		min-width: 57px;
    	        	}
    	        	.btn.btn-danger.btn-xs{
                        border-radius: 0;
                        background: #f24841;
                        transition: all .3s ease-in;
                        &:hover{
                        	background: #cd2922;
                        }
    	        	}
    	        	.count_cart{
    	        		min-width: 40px;
    	        	}
    	        }
    	    }
    }
    li:nth-of-type(1){
    	overflow: auto;
    	max-height: 296px;
    }
    .table-bordered{
    	border: none;
    	margin-bottom: 0;
    	background: $blue;
    	color: #fff;
    	font-size: 16px;
    	padding:10px;
    	&>div{
    		display: flex;
    		justify-content: flex-end;
    		padding: 5px 0;
    		span{
    			display: inline-block;
    			margin-left: 20px;
    		}
    	}
    } 
    .btn_cart{
    	background: $blue;
    	text-align: center;
    	border-top: 1px solid #fff;
    	a{
    		display: inline-block;
		    padding: 10px 40px;
		    color: #fff !important;
		    background: #e84814;
		    font-size: 16px;
		    font-weight: 400;
		    border: 1px solid #3b3b3b;
		    text-transform: uppercase;
		    font-size: 15px;
		    margin: 10px 0;
		    transition:all .3s ease-in;
		    &:hover{
		    	color: $color !important;
		    }
    	}
    }
    .text-center{
    	padding: 15px 0;
    	font-weight: 16px;
    }
}
.head_{
	display: block;
}
.head_fixed{
	display: none;
}
// fixed_menu=-=-=-=-=-=
#header_fix.act{
    opacity: 1;
    visibility: visible;
    transition: all .5s ease-in-out;
}
#header_fix{
    display: flex;
    width: 100%;
    background: $blue url(/catalog/view/theme/default/img/sherlock.png) no-repeat;
    position: relative;
    z-index: 1;
    height: 60px;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    max-width: 1200px;
    top: 0;
    z-index: 99;
    justify-content: space-between;
    .menu_fix_drop{
    	display: none;
    }
    .menu_fix{
    	width: 100%;
    	max-width: 277px;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	color: #fff;
    	font-size: 20px;
    	font-weight: 600;
    	text-transform: uppercase;
    	// span{
    	// 	display: inline-block;
    	// 	padding-right: 32px;
    	// 	background: url('/catalog/view/theme/default/img/menu_fix.png') no-repeat;
    	// 	background-position: 100%;
    	// 	cursor: pointer;
    	// 	transition: all .3s ease-in;
    	// 	&:hover{
    	// 		color: $color1;
    	// 	}
    	// }
    }
    	#search1{
    		background: $blue1;
    	    display: flex;
    	    align-items: center;
    	    padding: 0 15px;
    	    width: 100%;
    	    box-sizing: border-box;
    	    label{
    	    	width: 100%;
    	    	display: block;
    	    	max-width: inherit;
    	    	margin-bottom: 0;
    	    	font-weight: 400;
    	    }
    	    input{
    	    	background: transparent;
    	    	color: #fff;
    	    	line-height: 1;
    	    	border: none;
    	    	outline: none;
    	    	font-size: 16px;
    	    	padding-left: 10px;
    	    	box-sizing: border-box;
    	    	width: calc(100% - 36px);
    	    	@include placeholder{
                  color: #fff;
                  font-size: 16px;
    	    	}
    	    }
    	    button{
    	    	background: no-repeat;
    	    	border: none;
    	    	cursor: pointer;
    	    	outline: none;
    	    }
    	}
    	.wish_and_comp_fix{
    		display: flex;
    		align-items: center;
    		a:nth-of-type(1){
    			padding-right: 35px;
    		}
    	}

    #cart{
    	padding-left: 35px;
    	margin-bottom: 0;
    	padding-right: 25px;
    	&:hover > .dropdown-menu{
    		display: block;
    	}
    }
    .head_{
    	display: none;
    }
    .head_fixed{
    	display: block;
    }
    .wr_bl{
    	display: flex;
    	align-items:center;
    	justify-content: center;
    	text-align: center;
    	padding-left: 30px;
    	padding-right: 10px;
    	margin-right: 10px;
    	.wish_and_comp{
    		display: flex;
    		align-items: flex-end;
    		a:nth-of-type(1){
    			padding-right: 35px;
    		}
    	}
    		div{
    			display: inline-block;
    			position: relative;
    			min-height: 20px;
    			.icon_count{
    				position: absolute;
    				background: #f24841;
    				color: #fff;
    				top: -5px;
    				line-height: 1;
    				right: -10px;
    				border-radius: 50%;
    				width: 15px;
    				height: 15px;
    				font-size: 10px;
    				display: flex;
    				align-items: center;
    				justify-content: center;
    		}
    		span#wish_icon {
    		    right: -6px;
    		}

    	}
    	#cart{
    		padding-left: 30px;
    		padding-right: 0;
    		.btn_cart a:hover{
                text-decoration: none;
    		}
    		li > div{
	    		display: block;
	    	}
    	}
    	.login_c{
    		display: none;
    	}
    	p{
    		color: #fff;
    	}
    }
   #price_click .title{
   	display: none !important;
   }
   .wr_bl p.text-center {
       color: #000;
   }
}

.telephones{
	display: block;
}
.telephones_2{
	  display: none;
}

.hamburger-btn{
	display: none;
	position: relative;
	width: 35px;
	height: 22px;
	margin-left: 10px;
	span{
		position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: $color1;
	}
	span:nth-of-type(1) {
	    top: 0;
	}
	span:nth-of-type(2) {
	    top: 50%;
	}
	span:nth-of-type(3) {
	    top: 100%;
	}
}
#header_fix{
	.menu_fix{
		position: relative;
		.menu_fix_drop{
			position: absolute;
			top: 60px;
			left: 0;
			right: 0;
			background: $blue1;
			a{
				color: #fff;
				font-size: 14px;
				display: block;
				padding: 5px 0;
				transition:all .5s ease;
				&:hover{
					color: $color1;
					text-decoration: none;
				}
			}
			.menu_drop{
				display: none;
				&>ul{
					padding-left: 15px;
					list-style-type: none;
					li a{
                      font-size: 12px;
					}
				}
			}
			.menu_fixed{
				list-style-type: none;
				padding-left: 0;
				padding: 10px 5px 10px 15px;
				margin-bottom: 0;
				max-height: 400px;
				overflow: auto;
				li{
					position: relative;
					.arrow_menu_foxed{
						width: 30px;
					    height: 20px;
					    position: absolute;
					    top: 0px;
					    cursor: pointer;
						right: 0;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						z-index: 10;
						img{
							transform: rotate(-90deg);
							display: inline-block;
							transition: all .2s ease;
						}
					}
					.arrow_menu_foxed.act img{
						transform: rotate(0);
					}
				}
			}
		}
	}
	#price_click{
		background: $blue1;
	}
}
#block_menu #menu .menu_fix_child{
    display: none;
}
.arrow_menu_foxid{
	display: none;
}
.search_cl,.search_cl_1{
	display: none;
}
footer .wrap_footer .wrapper_link{
	display: flex;
	width: 80%;
	justify-content: space-between;
	flex-wrap:wrap;
	.wr_foot{
		width: calc(25% - 10px);

	}
}
.btn_foot{
	display: none;
}

// category-==-=-=-=-=

.category{
   padding: 0;
   max-width: 1200px;
   width: 100%;
   margin-top: 28px;
   .breadcrumb{
    border-radius: 0;
    font-size: 17px;
    text-transform: uppercase;
    background: none;
    padding: 8px 0;
    li a{
    	color: #000;
    }
    li:nth-last-of-type(1) a{
    	font-weight: 600;
    }
    li+li:before {
        color: #000;
        content: ">";
    }
   }
  .category_back{
  	margin: 15px 0 15px 0;
    background: url('/catalog/view/theme/default/img/back_cat.png') no-repeat;
    padding-left: 25px;
    background-position: 1px;
  	a{
  	  	font-size: 17px;
  	  	font-weight: 400;
  	  	color: #000;
  	  	transition:all .3s ease-in;
  	  	&:hover{
  	  		text-decoration: none;
  	  		color: $color1;
  	  	}
  	  }
  } 
  // .special_block .img img {
  //     max-width: inherit;
  //     height: inherit;
  // }
  #column-left{
  	padding: 0;
  	width: 100%;
  	max-width: 277px;
  	.list-group{
  		border-radius: 0;
  		.list-group-item{
  			font-size: 17px;
  			color: #000;
  			padding: 14px 15px 15px 25px;
  			text-transform: uppercase;
  			transition:all .3s ease-in;
  			border: none;
  			margin-bottom: 1px;
  			padding-right: 60px;
  			position: relative;
  			&:hover{
  				color: #fff;
  				background: $blue1;
	  			.category_span{
	  				color: #fff;
	  			}
  			}
  			.category_span{
  				position: absolute;
  				right: 17px;
  				max-width: 34px;
  				width: 100%;
  				text-align: center;
  				border: 1px solid #e9e9e9;
  				border-radius: 4px;
  				color: $blue1;
			    font-size: 14px;
			    padding-top: 1px;
			    top: calc(50% - 11px);
  			}
  		}
  		.list-group-item.active{
				color: #fff;
				background: $blue1;
  			.category_span{
  				color: #fff;
  			}
		}
  	}
  }
  .list-group-item:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
  }
  .box.mfilter-box{
    background: #fff;
  }
  .title_category{
	  	display: flex;
	    align-items: center;
	    justify-content: space-between;
	    padding: 13px 25px;
	    border-bottom: 1px solid #ededed;
	    div{
	    	color: $blue1;
	    	font-weight: 600;
	    	font-size: 17px;
	    }
	    a{
	    	font-size: 14px;
	    	font-weight: 400;
	    }
  }
  .mfilter-heading{
     background: #fff;
  }
  .title_b{
  	 font-size: 17px;
  	 font-weight: 300;
  	 line-height: 1.5;
  	 .t{
  	 	font-weight: 600;
  	 }
  	 .mfilter-heading-content{
  	 	padding: 13px 25px;
  	 }
  }
  .mfilter-opts-container {
      border-top: none;
  }
  .mfilter-content-opts .mfilter-options{
  	padding: 13px 25px;
  }
  .mfilter-price-inputs{
  	display: flex;
  	align-items:center;
  	justify-content: space-between;
  	.count_filter{
  		display: inline-block;
  		margin-right: 5px;
  		font-size: 15px;
  	}
  	.count_filter ~ .count_filter{
  		margin-left: 5px;
  	}
  	label{
  		width: 100%;
  		max-width: 85px;
  		display: inline-block;
  		margin-bottom: 0;
  		#mfilter-opts-price-min, #mfilter-opts-price-max{
  			width: 100%;
  			min-width: inherit;
  			box-sizing: border-box;
  			text-align: center;
  			color: $blue1;
  			font-weight: 600;
  			font-size: 17px;
  			height: 36px;
  		}
  	}

  }
  .title_b.mfilter-heading{
     color: $blue1;
     font-weight: 600;
     font-size: 17px;
  }
  .mfilter-heading{
  	position: relative;
  	.mfilter-head-icon {
  	      display: block;
          float: inherit;
          background: 0 0;
          color: #e84814;
          transition: all .5s ease;
          width: inherit;
          height: inherit;
          margin-right: 0;
          margin-top: 0;
          position: absolute;
          right: 25px;
          top: 13px;
          font-size: 26px;
  	  }
  } 
  .mfilter-content {
      border: 1px solid #fff !important;
      border-radius: 0 !important;
  }
  .mfilter-price-slider{
  	padding: 0 !important;
  	margin-right: 0px !important;
  	background: #ededed !important;
  	 #mfilter-price-slider{
  	 	border: none !important;
  	 	background: none !important;
  	 	height: 6px !important;
  	 	border-radius: 0 !important;
  	 	margin: 36px 0 0 0 !important;
  	 	.ui-slider-range{
  	 		background: $blue1!important;
  	 	}
  	 	.ui-slider-handle{
  	 		top: -18px;
  	 		margin-left: -8px !important;
  	 		background: url('/catalog/view/theme/default/img/reg.png') no-repeat !important;
  	 		border-radius: 0 !important;
  	 	}
  	}
  }
  .mfilter-option{
  	input[type="checkbox"]{
  		display: none;
  	}
  	.mfilter-col-input{
  		width: 15px;
  		height: 15px;
  	}
  	label.mfilter-tb-as-td{
  		position: relative;
  		padding-left: 12px;
  		font-size: 15px;
  		text-transform: uppercase;
  		font-weight: 400;
  		&:after{
  			content: ' ';
  			display: block;
  			width: 15px;
  			height: 15px;
  			background: #fff;
  			border: 1px solid #d1d1d1;
  			position: absolute;
  			left: -15px;
  			top: calc(50% - 7.5px);
  		}
  	}
  	&.mfilter-input-active{
  		label.mfilter-tb-as-td{
  			position: relative;
  			padding-left: 12px;
  			&:after{
  				content: ' ';
  				background: $blue1 url('/catalog/view/theme/default/img/arr.png') no-repeat center;
  				border: 1px solid $blue;
  				background-size: 9px;
  				position: absolute;
  			}
  		}
  	}
  	.mfilter-counter.mfilter-close{
        display: none;
  	}
  	.mfilter-tb-as-td {
  	    border-top: none !important;
  	}
  	.mfilter-counter {
  	    border-radius: 0;
  	}
  }
  .media{
  	background: #fff;
  	min-height: 150px;
  	padding: 20px;
  	box-sizing: border-box;
    margin-bottom: 20px;
    border-bottom: 1px solid #fbfbfb;
    .media-heading{
    	font-size: 19px;
    	color: #000;
    	font-weight: 600;
    	margin-bottom: 10px;
    }
    .descr{
    	font-size: 12px;
    	font-weight: 400;
    	margin-bottom: 10px;
    }
    a{
    	font-size: 14px;
    	color: $blue1;
    	font-weight: 500;
    	transition:all .3s ease-in;
    	&:hover{
    		color: $color1;
    		text-decoration: none;
    	}
    }
  }
.back_btn{
	display: none;
}

  h1{
  	font-size: 17px;
  	font-weight: bold;
  	text-transform: uppercase;
  	margin: 35px 0 15px 0;
  }
  .wr_limit_sort{
  	background: #fff;
  	padding: 11px 25px;
  	box-sizing:border-box;
  	display: flex;
  	justify-content: space-between;
  	align-items: center;
  	margin-bottom: 16px;
  	.count_product{
  	    color: $blue1;
  	    font-size: 17px;
  	    font-weight: 500;
  	    .product_color{
  	    	color: #000;
  	    }
  	}
  	.sort_pro{
  		color: #000;
  		font-size: 17px;
  		font-weight: 500;
  		label{
  			margin-bottom: 0;
  			margin-left: 10px;
  			position: relative;
  			background: url('/catalog/view/theme/default/img/back_cat_1.png') no-repeat;
  			background-position: 95%;
  			display: inline-block;
  			background-size: 14px;
  			#input-sort{
  				color: $blue1;
  				border: none;
  				width: 100%;
  				padding-right: 30px;
  				-webkit-appearance: none;
				  -moz-appearance: none;
				  appearance: none;
				  background: none;
  			}
             
  		}
  	}
  	.limit_pro{
  		display: flex;
  		font-size: 17px;
  		font-weight: 500;
  		ul{
  			display: flex;
  			list-style-type: none;
  			padding-left: 10px;
  			margin-bottom: 0;
  			li{
  				margin-right: 8px;
  			}
  			li.active a{
  				color: $blue1;
  			}
  			li a{
  				color: #cacaca;
  				transition: all .3s ease-in;
  				&:hover,&:focus{
  					color: $blue1;
  					text-decoration: none;
  				}
  			}
  		}
  	}
  	.grid_list{
  		button{
  			width: 17px;
  			height: 17px;
  			display: inline-block;
  			border: none;
  		}
  		#list-view{
  			background: url('/catalog/view/theme/default/img/list.png') no-repeat;
  		}
  		#grid-view{
  			background: url('/catalog/view/theme/default/img/grid.png') no-repeat;
  		}
  		#list-view.active{
  			background: url('/catalog/view/theme/default/img/list_color.png') no-repeat;
  		}
  		#grid-view.active{
  			background: url('/catalog/view/theme/default/img/grid_color.png') no-repeat;
  		}
  	}

  }
  #content.categ{
  	width: calc(100% - 277px);
  	padding-left: 30px;
  	float: right;
  	// overflow: hidden;
  }
  .wr_special_block{
  	display: flex;
  	justify-content: flex-start;
  	flex-wrap:wrap;
  	margin: 0 -15px;
  	.special_block{
  		margin: 0 15px;
  		background: #fff;
  		width: calc(33.333% - 30px);
  		margin-bottom: 30px;
  		text-align: center;
  	}
  }
  .special_block.list{
    display: flex;
    justify-content: space-between;
    max-width: inherit;
    margin-bottom: 1px;
    position: relative;
    padding: 10px 20px;
    align-items: center;
    width: 100%;
    .description{
    	min-height: 36px;
    	max-height: 36px;
    }
    .wr_title{
    	padding: 15px 30px 0 40px;
	    box-sizing: border-box;
	    max-width: 430px;
	    width: 100%;
	    border-right: 1px solid #eee;
	    border-left: 1px solid #eee;
	    .title {
	        min-height: inherit;
	    }
    	.title,.sku{
    		display: inline-block;
    	}
    	.stiker_wr_list{
    		div{
    			display: inline-block;
    			margin-right: 4px;
    		}
    	}
    }
    .wr_price{
    	width: 100%;
    	max-width: 280px;
    	margin-left: 20px;
    }
    .img{
    	min-width: 160px;
    	margin-right: 20px;
    	img{
    		max-width: 100%;
    		height: auto;
    	}
    }
       &:after{
       	content: '';
       	display: block;
       	position: absolute;
       	bottom: 0;
       	left: 0;
       	right: 0;
       	box-shadow: 0 0 3px 1px rgba(0,0,0,0.1);
       }
  }
// .wr_special_block.product-list .special_block.grid{
//      display: none;
// }
// .wr_special_block.product-grid .special_block.list{
// 	     display: none;
// }





}
.wrap_success{
	position: fixed;
	top: 50%;
	transform: translate(-50%);
	left: 50%;
	z-index: 9999;
	.alert.alert-success{
      color: #fff;
      background-color: $blue;
      border-color: transparent;
      border-radius: 0;
      text-align: center;
      min-width: 300px;
      // padding-right: 30px;
      font-size: 14px;
      line-height: 1.2;
      i{
      	font-size: 20px;
      	margin-right: 5px;
      }
      a{
      	color: $color1;
      	position: relative;
      	&:hover{
      		text-decoration: none;
      	}
      	&:hover:before{
      		-webkit-transform: scaleX(1);
      		transform: scaleX(1);
      	}
      	&:before{
      		content: '';
      		position: absolute;
      		transition: transform .5s ease;
      		left: 0;
      		bottom: -1px;
      		width: 100%;
      		height: 1px;
      		background: #fff;
      		-webkit-transform: scaleX(0);
      		transform: scaleX(0);
      	}
      	&:after{
      		content: '';
      		position: absolute;
      		-webkit-transition: -webkit-transform .5s ease;
      		transition: -webkit-transform .5s ease;
      		transition: transform .5s ease;
      		transition: transform .5s ease,-webkit-transform .5s ease;
      	}
      }
	}
	.close{
		position: absolute;
		right: 5px;
		top: -2px;
		color: #fff;
		opacity: 0.8;
		font-weight: 100;
	}
}
#callback_1{
	position: fixed;
	width: 200px;
	height: 40px;
	transform:rotate(-90deg);
	right: -80px;
	top: 50%;
	z-index: 101;
	font-size: 15px;
	font-weight: 400;
	background: $blue1;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all .3s ease-in;
	span{
		margin-left: 15px;
		display: inline-block;
	}
   &:hover{
   	   background: $blue;
   }
}
body #popup-callback{
	.btn-callback.ladda-button{
		font-size: 16px;
		text-transform: uppercase;
		width: 100%;
		padding: 8px 0;
		background: #e84814;
		border: 2px solid #e84814;
		color: #fff;
		cursor: pointer;
		transition: all .5s ease;
		border-radius: 0;
		&:hover{
			background: #393939;
		}
	}
	.popup-center{

		.form-group{
           span{
           	display: none;
           }
           .input-group{
           	width: 100%;
           	input{
           		border-radius: 0;
           	}
           }
		}
	}
	.popup-footer {
	    height: inherit;
	}
	.popup-heading {
	    font-size: 16px;
	    text-transform: uppercase;
	}
	.mfp-close{
		font-size: 49px;
		height: 45px;
		right: 0px;
		top: -2px;
		width: 43px;
		z-index: 1060;
		opacity: 0.8;
		font-weight: 100;
		color: #e84814;
		&:hover{
			opacity: 1;
		}
	}
	.download_file{
		width: 25px;
		height: 25px;
		position: absolute;
		right: 17px;
		bottom: 7px;
	}
	#contact-comment{
		min-height: 80px;
	}
}

.mfp-content #popup-success-callback {
    background: #fff;
    border: 1px solid $blue1;
    color: #000;
    line-height: 25px;
    margin: 0 auto;
    max-width: 650px;
    min-height: 50px;
    padding: 20px 30px;
    position: relative;
    width: 90%;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    p{
    	font-size: 14px;
    	text-transform: initial;
    	margin-top: 20px;
    }
}

#popup-purchase-wrapper{
	.popup-heading {
	    font-size: 16px;
	    text-transform: uppercase;
	    text-align: left;
	    background-color: $blue1;
	    color: #fff;
	}
	.popup-center{
		.left{
           .product-name {
               font-size: 18px;
               margin-bottom: 10px;
           }
           .product-price {
               font-size: 16px;
           }
		}
		.right{
           img{
           	border: none;
           }
		}
		.alert-danger,.alert-info {
		    border-radius: 0;
		}
		& > p{
          font-size: 16px;
          text-align: center;
          line-height: 1.5;
          text-transform: uppercase;

		}
		& > p + p{
          font-size: 14px;
          text-align: center;
          line-height: 1.2;
          text-transform: inherit;
		}
	}
	.payment-info > div{

		label {
		    display: none;
		}
		input{
			padding: 10px 15px;
			font-size: 14px;

		}
		.payment-quantity{
			margin-top: 10px;
			label {
			    display: block;
			    font-size: 11px;
			    line-height: 12px;
			    color: #000;
			    font-weight: bold;
			    margin-bottom: 4px;
			    margin-top: 4px;
			}
			.btn-plus,.btn-minus{
				button {
					    background: #ededed;
					}
			} 
		}
	} 
	.popup-footer{
		text-align: left;
		background-color: #ededed;
		height: inherit;
		button{
			display: none;
		}
	}
	#purchase-form{
		select.form-control{
			border-radius: 0;
		}
		.radio,.checkbox{
			label{
				display: block;
				position: relative;
				padding-left: 25px;
				&:after {
				    content: ' ';
				    display: block;
				    width: 22px;
				    height: 22px;
				    position: absolute;
				    border: 1px solid $blue1;
				    top: -3px;
				    left: -5px;
				    border-radius: 50%;
				}
				&:before {
				    content: ' ';
				    display: block;
				    width: 14px;
				    height: 14px;
				    position: absolute;
				    border: 1px solid $blue1;
				    top: 1px;
				    left: -1px;
				    background:$blue1; 
				    border-radius: 50%;
				    opacity: 0;
				}
			}

			input[type="radio"]:checked + label:before{
				opacity: 1;
		}
	 }
	}
	#popup-checkout-button{
		font-size: 16px;
		text-transform: uppercase;
		width: 100%;
		padding: 8px 0;
		background: #e84814;
		border: 2px solid #e84814;
		color: #fff;
		cursor: pointer;
		transition: all .5s ease;
		border-radius: 0;
		height: inherit;
		&:hover{
			background: #393939;
			text-decoration: none;
		}
	}

}
#btn_filter{
	position: fixed;
    width: 40px;
    height: 40px;
    left: 2px;
    top: 23%;
    z-index: 10;
    display: none;
    cursor: pointer;
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid $blue;
}
.category a.load_more{
	font-size: 17px;
	font-weight: 500;
	color: $blue;
	width: 100%;
    max-width: 310px;
    border-radius: 0;
    border: 3px solid $blue;
    padding: 10px 15px;
    transition: all .3s ease-in;
    background: url(/catalog/view/theme/default/img/refresh-button.svg) no-repeat;
        background-size: 25px;
        background-position: 20% center;
    &:hover{
    	background:$blue1 url(/catalog/view/theme/default/img/refresh-button_1.svg) no-repeat;
    	    background-size: 25px;
    	    background-position: 20% center;
    	color: #fff;
    }
}
.title_mf{
	display: none;
	padding-left: 26px;
	color: $blue1;
	font-weight: 600;
	font-size: 17px;
}
.mfilter-selected-filters{
	padding: 13px 25px;
	.mfilter-selected-filters-cnt{
		a{
			font-size: 15px;
			text-transform: uppercase;
			font-weight: 400;
			color: #000;
		}
	}
}
.pagination>li {
    display: inline-block;
}
.pagination>li>a, .pagination>li>span {
    margin: 0 2px;
    font-size: 15px;
    padding: 7px 14px;
    font-weight: 300;
    color: #000;
    border:1px solid #c6c6c6;
}
.pagination>li:last-child>a, .pagination>li:last-child>span {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    z-index: 3;
    color: #fff;
    background-color: $blue1;
    border-color: #c6c6c6;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: $blue1;
    border-color: #c6c6c6;
}

// product=-=-=

.product_wr{
   .breadcrumb{
    border-radius: 0;
    font-size: 17px;
    text-transform: uppercase;
    background: none;
    padding: 8px 0;
    margin: 35px 0;
    li a{
    	color: #000;
    }
    li:nth-last-of-type(1) a{
    	font-weight: 600;
    }
    li+li:before {
        color: #000;
        content: ">";
    }
   }
   .back_btn{
   	margin: 15px 0 15px 0;
     background: url('/catalog/view/theme/default/img/back_cat.png') no-repeat;
     padding-left: 25px;
     background-position: 1px;
   	a{
   	  	font-size: 17px;
   	  	font-weight: 400;
   	  	color: #000;
   	  	transition:all .3s ease-in;
   	  	&:hover{
   	  		text-decoration: none;
   	  		color: $color1;
   	  	}
   	  }
   } 

   .left{
   	// max-width: 550px;
   	background: #fff;
   	// width: 100%;
   	padding: 40px 0;
   	box-sizing: border-box;
   	display: table-cell;
   	vertical-align: top;
   	.image_main{
   		width: 100%;
   		max-width: 550px;
   		padding: 0 15px;
   	}
   	.swiper-button-next {
   	    background-image: url('/catalog/view/theme/default/img/arrow_slider.png');
   	    width: 16px;
   	    height: 24px;
   	    right: 20px;
   	    left: auto;
   	    transform: rotate(180deg);
   	    background-size: 16px 24px;
   	    margin-top: 0;
   	    top: calc(50% - 12px)
   	}
   	.swiper-button-prev {
   	    background-image: url('/catalog/view/theme/default/img/arrow_slider.png');
   	    left: 20px;
   	    right: auto;
   	    width: 16px;
   	    height: 24px;
   	    background-size: 16px 24px;
   	    margin-top: 0;
   	    top: calc(50% - 12px)
   	}
   	.prod-zoom_wr{
   		position: relative;
   	}
   	.image_main{
   		.swiper-container.prod-zoom{
          max-width: 450px;
          margin: 0 auto;
          .swiper-slide{
          	border: 1px solid #f2f2f2;
          	box-sizing: border-box;
          }
   		}
   		.fancy{
   			display: block;
   			.zoomWrapper{
   				margin: auto;
   			}
   		}
   	}
   }
   .right{
   	width: calc(100% - 550px);
   	box-sizing: border-box;
   	padding: 40px 35px;
   	background: #fff;
   	border-left: 1px solid #eeeeee;
   	display: table-cell;
   	vertical-align: top;
   	h1{
   		font-size: 30px;
   		line-height: 1.2;
   		color: #000;
   		font-weight: 600;
   		text-transform: uppercase;
   		margin: 0;
   	}
   	.manuf_wr{
   		display: flex;
   		align-items: center;
   		justify-content: space-between;
   		margin-bottom: 25px;
   		margin-top: 20px;
   		.manuf,.pr_model{
   			a{
   				color: #3b3b3b;
   				text-transform: uppercase;
   				font-size: 13px;
   				font-weight: 500;
   				transition:all .3s ease-in;
   				&:hover{
   					color: $color1;
   					text-decoration: none;
   				}
   			}
   			span+span{
   				color: #3b3b3b;
   				text-transform: uppercase;
   				font-size: 13px;
   				font-weight: 500;
   			}
   		}
   		.rating{
   			i{
   				font-size: 16px;
   			}
   			.empty_star{
   				font-size: 16px;
   				color: #ededed;
   			}
   			.full_star{
   				font-size: 16px;
   				color: #ffbb05;
   			}
   			.count_star{
   				color: #3b3b3b;
   				font-size: 13px;
   				margin-left: 10px;
   				display: inline-block;
   			}
   		}
   	}
   	.wr_price{
   		display: inline-block;
   		margin-top: 25px;
   		.old_price{
   			font-size: 27px;
   			color: #e84814;
   			font-weight: 500;
   			text-align: center;
   			span{
   				font-size: 14px;
			    padding-left: 5px;
			    position: relative;
			    display: inline-block;
			    top: -9px;
   			}
   		}.new_price{
   			font-size: 40px;
   			color: #000;
   			font-weight: 700;
   			span{
   				font-size: 21px;
			    padding-left: 5px;
			    display: inline-block;
			    position: relative;
			    top: -13px;
   			}
   		}
   		.wr_price_text{
   			font-size: 15px;
   			color: #000;
   			font-weight: 400;
   			text-align: center;
   		}
   	}
   	.wr_price_prod{
   		display: flex;
   		justify-content: space-between;
   		.wr_click{
   			width: 100%;
   			max-width: 182px;
   			display: flex;
   			flex-direction: column;
   			#product_qu{
   				display: flex;
   				justify-content: space-between;
   				margin-bottom: 14px;
   				.minus{
   					display: inline-block;
				    width: 54px;
				    height: 48px;
				    border-radius: 0;
				    position: relative;
				    background: #fff;
				    border: 1px solid #e3e3e3;
				    &:after{
				    	content: ' ';
				    	display: block;
				    	position: absolute;
				    	width: 19px;
				    	height: 4px;
				    	background: #e84814;
				    	left: calc(50% - 9.5px);
				    	top: calc(50% - 2px);
				    }
   				}
   				.plus{
	   					display: inline-block;
					    width: 54px;
					    height: 48px;
					    border-radius: 0;
					    position: relative;
					    background: #fff;
					    border: 1px solid #e3e3e3;
   					&:after{
   						content: ' ';
   						display: block;
   						position: absolute;
   						width: 19px;
   						height: 4px;
   						background: $blue1;
   						left: calc(50% - 9.5px);
   						top: calc(50% - 2px);
   					}
   					&:before{
   						content: ' ';
   						display: block;
   						position: absolute;
   						width: 4px;
   						height: 19px;
   						background: $blue1;
   						top: calc(50% - 9.5px);
   						left: calc(50% - 2px);
   					}
   				}
   				#input_qu{
   					max-width: 75px;
   					height: 48px;
   					text-align: center;
   					border-radius: 0;
   					font-size: 31px;
   					border-radius: 0;
   					color: #000;
   					border:1px solid #e3e3e3;
   				}
   			}
   			.btn_cl{
/*    				.btn_buy,.btn_one{
					font-size: 20px;
					color: #fff;
                    width: 100%;
                    background: #e84814;
                    height: 48px;
                    font-weight: bold;
                    border: none;
                    overflow: hidden;
                    position: relative;
                    backface-visibility: hidden;
                    &:after{
                       content: '';
	                   position: absolute;
	                   z-index: -1;
	                   transition: all 0.3s;
                    }
                    span {
                        display: inline-block;
                        transition: all 0.3s;
                        backface-visibility: hidden;
                    }
                    &:before{
                       content: " ";
                       position: absolute;
                       height: 100%;
                       width: 100%;
                       line-height: 1.5;
                       font-size: 180%;
                       transition: all 0.3s;
                       left: 0;
                       top: -100%;
                       font-size: 30px;
                    }
                    &:hover:before {
					    top: 0;
					}
					&:hover span {
					    -webkit-transform: translateY(300%);
					    -moz-transform: translateY(300%);
					    -ms-transform: translateY(300%);
					    transform: translateY(300%);
					}
					&:active{
						background: #ea592a;
						top: 2px;
					}
} */
		        .btn_buy{
		        		border: none;
		        		font-size: 20px;
		        		text-transform: uppercase;
		        		width: 100%;
		        		height: 48px;
		        		background: $color1;
		        		border: 2px solid $color1;
		        		color: #fff;
		        		cursor: pointer;
		        		transition: all .5s ease;
		        		position: relative;
		        		z-index: 1;
		        		&:before {
						    content: ' ';
						    position: absolute;
						    width: 100%;
						    left: 0;
						    right: 0;
						    background: #fff;
						    height: 0;
						    top: 0;
						    z-index: -1;
						    transition:all 0.3s ease;
						}
						&:hover{
							color: $color1;
						}
						&:hover:before{
							height: 100%;
						}
		        }
		        .btn_one{
		        	margin-top: 13px;
	        		border: none;
	        		font-size: 20px;
	        		text-transform: uppercase;
	        		width: 100%;
	        		height: 48px;
	        		background: #fff;
	        		border: 2px solid #00ad5d;
	        		color: #00ad5d;
	        		cursor: pointer;
	        		transition: all .5s ease;
	        		position: relative;
	        		z-index: 1;
	        		&:before {
					    content: ' ';
					    position: absolute;
					    width: 100%;
					    left: 0;
					    right: 0;
					    background: #00ad5d;
					    height: 0;
					    top: 0;
					    z-index: -1;
					    transition:all 0.3s ease;
					}
					&:hover:before{
						height: 100%;
					}
					&:hover{
						color: #fff;
					}
		        }

   				// .btn_one{
   				// 	margin-top: 13px;
   				// }
   				// .icon-cart:before{
   				// 	content: "\f07a";
   				// }
   				// .btn_one{
   				// 	background: #fff;
   				// 	border: 2px solid #00ad5d;
   				// 	color: #00ad5d;
   				// }
   				// .btn_one.icon-cart:before{
   				// 	content: "\f218";
   				// }
   				
   			}
   			.wr_click_cl{
   				font-size: 15px;
   				line-height: 1.5;
   				font-weight: 400;
   				text-align: center;

   			}
   		}

   	}
   	#share42{
      a{
      	background: url('/catalog/view/theme/default/img/face_p.png') no-repeat !important;
      	width: 25px;
      	height: 25px;
      	&:hover{
      		opacity: 0.8;
      	}
      }
      a + a{
      	background: url('/catalog/view/theme/default/img/g_p.png') no-repeat !important;
      	width: 25px;
      	height: 25px;
      }
      a + a + a{
      	background: url('/catalog/view/theme/default/img/tw_p.png') no-repeat !important;
      	width: 25px;
      	height: 25px;
      }
   	}
   	.wish_comp_bottom_wr{
   		display: flex;
   		justify-content: space-between;
   		margin-top: 20px;
	   	.wish_comp_bottom{
	   		p{
	   			margin-bottom: 10px;
	   		}
	   		p button{
	   			padding-left: 35px;
			    height: 25px;
			    border: none;
			    font-size: 15px;
			    font-weight: 400;
			    background: none;
			    background: url(/catalog/view/theme/default/img/heart_p.png) no-repeat;
			    background-position: 0 center;
			    transition:all .3s ease;
			    &:hover{
			    	color: $color1;
			    }
	   		}
	   		 p+ p button{
	   			background: url(/catalog/view/theme/default/img/scale_p.png) no-repeat;
	   			width: 25px;
	   			background-position: 0 center;
	   			transition:all .3s ease;
	   			&:hover{
	   				color: $color1;
	   			}
	   		}
	   	}
	   	.wr_share{
	   		display: flex;
	   		.wr_share_text{
	   			max-width: 180px;
	   		}
	   	}
   	}

   }
   .form-control:focus {
       border-color: #f2f2f2;
       outline: 0;
       box-shadow: none;
   }
   .product_wrapper{
   	display: flex;
   	align-items: flex-start;
   	display: table;
   	.swiper-container-android{
   		.swiper-pagination-bullet {
   		    width: 10px;
   		    height: 10px;
   		    opacity: 0.6;
   		}
   		.swiper-pagination-bullet-active {
   		    opacity: 1;
   		    background: #f24841;
   		}
   	}
   	.short_description {
   		font-size: 15px;
   		line-height: 1.5;
   		color: #000;
   		font-weight: 300;
   		margin-bottom: 10px;
   		text-align: justify;
   	}
   }
    .image_main{
    	.slider-for{
           text-align: center;
           a{
           	cursor: zoom-in;
           	img{
           		display: inline-block;
           	}
           }
        [data-video]{
        	display: inline-block;
        	background-size: auto !important;
        }
    	}
    	.slider-nav{
           max-width: 480px;
           margin: 0 auto;
           margin-top: 30px;
           img{
           	border: 1px solid #f2f2f2;
           	cursor: pointer;
           }
           .slick-arrow{
           	display: block;
           	background: url(/catalog/view/theme/default/img/arrow_slider.png) no-repeat;
           	width: 16px;
           	height: 26px;
           	transform: rotate(180deg);
           	top: calc(50% - 13px);
           	&:before{
           		display: none;
           	}
           }
           .slick-prev.slick-arrow{
           	transform: rotate(0);
           }
    	}
    	.slider_wr{
    		margin: 0 25px;
    		box-sizing: border-box;
    		position: relative;
    		.sticker_f{
    			position: absolute;
    			z-index: 2;
    			left: 0;
    			top: 0;
    		}

    	}
    }
    .welcome{
    	margin-top: 5px;
    }
    .checkbox{
       input[type="checkbox"]{
       	display: none;
       }
       input[type="checkbox"]:checked + label:after{
       	content: ' ';
       	    background: $blue1 url(/catalog/view/theme/default/img/arr.png) no-repeat center;
       	    border: 1px solid $blue;
       	    background-size: 9px;
       	    position: absolute;
       }
       label{
       	position: relative;
       	&:after{
       		content: ' ';
   		    display: block;
   		    width: 15px;
   		    height: 15px;
   		    background: #fff;
   		    border: 1px solid #d1d1d1;
   		    position: absolute;
   		    left: 0;
   		    top: 0;
       	}
       }
    }
     .radio{
        input[type="radio"]{
        	display: none;
        }
        input[type="radio"]:checked + label:before{
        	content: ' ';
		    border: 1px solid $blue;
		    background-size: 9px;
		    position: absolute;
		    opacity: 1;
        }
        label{
        	position: relative;
        	padding-left: 30px;
        	&:after{
        		content: ' ';
    		    display: block;
    		    width: 18px;
    		    height: 18px;
    		    border-radius: 50%;
    		    background: #fff;
    		    border: 1px solid #d1d1d1;
    		    position: absolute;
    		    left: 0;
    		    top: 0;
        	}
	    	&:before{
	    		content: ' ';
	    		display: block;
	    		width: 10px;
	    		height: 10px;
	    		border-radius: 50%;
	    		background: $blue;
	    		position: absolute;
	    		left: 4px;
	    		top: 4px;
	    		z-index: 1;
	    		opacity: 0;
	    	}
        }
     }
    select.form-control{
    	border-radius: 0;

    }
    .review_wr{
    	background: #fff;
    	.review_wr_{
    		max-width: 1160px;
    		margin: 0 auto;
    		padding: 25px 0;
    	}
    	.wr_tab{
           border-bottom: 1px solid #ededed;
           .tabs_nav{
           	border: none;
           	max-width: 1025px;
           	margin: 0 auto;
           	display: flex;
           	padding-left: 0;
           	list-style-type: none;
           	flex-wrap:wrap;
           	li{
           		a{
           			border: none;
                    font-size: 17px;
                    color: $blue1;
                    line-height: 1.5;
                    font-weight: 600;
                    border-radius: 0;
                    margin-right: 0;
                    padding: 10px 20px;
                    display: inline-block;
                    &:hover,&:focus{
                    	text-decoration: none;
                    }
           		}
           	}
       		li.current{
       			a{
       	         color: #fff;
       	         background: $blue1;
       			}
       		}
           }
    	}
    	.tab_content{
    		max-width: 1025px;
    		margin: 0 auto;
    		#tab-description{
    			font-size: 15px;
    			line-height: 24px;
    			color: #000;
    		}
    		.tabs_item{
    			margin-top: 20px;
    		}
    		.form-review_top{
    			display: flex;
    			justify-content: space-between;
    			align-items:center;
    			margin-bottom: 50px;
    			.rev_pr_wr{
    				display: flex;
    				.rev_pr{
    					font-size: 17px;
    					line-height: 24px;
    					color: #000;
    					text-transform: uppercase;
    					margin-right: 40px;
    				}
    				.rating{
    					i{
    						font-size: 20px;
    					}
    					.fa.full_star{
    						color: #ffbb05;
    					}
    					.fa.empty_star{
    						color: #ededed;
    					}
    				}
    				.count_star{
    					font-size: 15px;
    					line-height: 1.5;
    					color: #000;
    					padding-left: 10px;
    					vertical-align: top;
    				}
    			}
				/*     			.btn_popup{
					background: #00ad5d;
					border: none;
					color: #fff;
					padding: 7px 20px;
					font-size: 15px;
					line-height: 1.5;
					border: 2px solid #00ad5d;
					transition:all .3s ease-in;
					&:hover{
						background: #1bd17d;
					}
				} */
	        	.btn_popup{
	        		font-size: 15px;
	        		text-transform: uppercase;
	        		width: 100%;
	        		padding: 7px 20px;
	        		background: #00ad5d;
	        		border: 2px solid #00ad5d;
	        		color: #fff;
	        		cursor: pointer;
	        		transition: all .5s ease;
	        		position: relative;
	        		z-index: 1;
	        		&:before {
					    content: ' ';
					    position: absolute;
					    width: 100%;
					    left: 0;
					    right: 0;
					    background: #fff;
					    height: 0;
					    top: 0;
					    z-index: -1;
					    transition:all 0.3s ease;
					}
					&:hover:before{
						height: 100%;
					}
					&:hover{
						color: #00ad5d;
					}
	        	}

    		}
    		.block_review{
    			display: flex;
    			margin-bottom: 20px;
    			.autor_wr{
    				width: 100%;
    				max-width: 135px;
    				.author{
    					font-size: 15px;
    					line-height: 1.5;
    					color: #000;
    					font-weight: 600;
    				}
                    .date_added{
                    	font-size: 15px;
                    	line-height: 1.5;
                    	color: #000;
                    	font-weight: 400;
                    	margin-top: 5px;
                    }
    			}
    			.descr{
    				width: calc(100% - 135px);
    				i{
    					font-size: 15px;
    				}
    				.fa.full_star{
    					color: #ffbb05;
    				}
    				.fa.empty_star{
    					color: #ededed;
    				}
    				.review_text{
    					margin-top: 15px;
    					font-size: 15px;
    					line-height: 1.5;
    					color: #000;
    				}
    			}
    		}
    		fieldset{
    			legend{
                  display: none;
    			}
    		}
    		.modal-content{
              padding: 20px;
              border-radius: 0;
              .form-control{
              	border-radius: 0;
              	font-size: 15px;
              	line-height:1.5;
              	color: #000;
              }
              .control-label{
              	font-size: 15px;
              	color: #000;
              	font-weight: 600;
              	margin-bottom: 5px;
              }
    		}
    		.cap{
    			display: flex;
    			img{
    				margin-left: 20px;
    			}
    		}
    		.star{
    			display: flex;
    			align-items: center;
    			justify-content: space-between;
    			clear: both;
    			margin: 10px 0;
    		}
    		.alert.alert-success{
               font-size: 15px;
               border-radius: 0;
               margin-bottom: 0;
    		}
    		.alert.alert-danger{
    			color: #a94442;
			    background-color: #f2dede;
			    border-color: #ebccd1;
			    border-radius: 0;
    		}

    		// #button-review{
    		// 	background: #00ad5d;
    		// 	border: none;
    		// 	color: #fff;
    		// 	padding: 7px 20px;
    		// 	font-size: 15px;
    		// 	line-height: 1.5;
    		// 	border: 2px solid #00ad5d;
    		// 	transition: all .3s ease-in;
    		// 	border-radius: 0;
    		// 	&:hover{
    		// 		background: #1bd17d;
    		// 	}
    		// }
        	#button-review{
        		font-size: 15px;
        		text-transform: uppercase;
        		width: 100%;
        		padding: 7px 20px;
        		background: #00ad5d;
        		border: 2px solid #00ad5d;
        		color: #fff;
        		cursor: pointer;
        		transition: all .3s ease-in;
        		position: relative;
        		z-index: 1;
        		border-radius: 0;
        		&:before {
				    content: ' ';
				    position: absolute;
				    width: 100%;
				    left: 0;
				    right: 0;
				    background: #fff;
				    height: 0;
				    top: 0;
				    z-index: -1;
				    transition:all 0.3s ease;
				}
				&:hover:before{
					height: 100%;
				}
				&:hover{
					color: #00ad5d;
				}
        	}
			#rating_w{
				display: inline-flex;
				justify-content: space-between;
				flex-direction: row-reverse;
				margin-top: 10px;
				input:checked ~ label {
				    color: rgb(244, 202, 0);
				    background: 0px 0px;
				}
				label {
				    display: inline-block;
				    color: #000;
				    background: 0 0;
				    max-width: 140px;
				    width: 100%;
				    text-align: center;
				    font-size: 14px;
				    padding: 9px 15px;
				    box-sizing: border-box;
				    cursor: pointer;
				    margin-bottom: -2px;
				    cursor: pointer;
				    padding: 0;
				    color: #999;
				    margin-right: 2px;
				    .fa {
				        font-size: 20px;
				    }
				}
				label:hover {
				    color: #f4ca00;
				}
				label:hover~label {
				    color: #f4ca00;
				}
			}
    	}
    }
}

// product-end=-=-=-=-

// blog=-=-=
#blog_popular{
	clear: both;
	margin-bottom: 40px;
}
.blog_latest{
	.breadcrumb{
	 border-radius: 0;
	 font-size: 17px;
	 text-transform: uppercase;
	 background: none;
	 padding: 8px 0;
	 margin: 35px 0;
	 li a{
	 	color: #000;
	 }
	 li:nth-last-of-type(1) a{
	 	font-weight: 600;
	 }
	 li+li:before {
	     color: #000;
	     content: ">";
	 }
	}
	#column-left{
		width: 100%;
		max-width: 277px;
		padding: 0;
		margin-right: 25px;
		.list-group{
			ul{
				padding: 0;
				list-style-type: none;
				margin-bottom: 0;
				.list-group-item{
					color: $blue1;
					font-size: 17px;
					font-weight: 500;
					border-radius: 0;
					background: #fff url(/catalog/view/theme/default/img/back_cat_1.png) no-repeat;
					background-position: 94%;
					background-size: 12px 7px;
				}
				.level_2{
					.list-group-item.active{
						color: $blue1;
						text-decoration: underline;
					}
					.list-group-item{
						background: #fff;
						font-weight: bold;
						font-size: 15px;
						color: #000;
						font-weight: 400;
						text-decoration: none;
					}
				}
				li a{
					border: none;
				}
			}
		}
	}
	.showcase-list{
		width: calc(100% - 302px);
		float: right;

	}
	.showcase-list_categ{
		margin: 0 -13px;
		display: flex;
		flex-wrap: wrap;
            .showcase-list_categ_child{
            	width: calc(50% - 26px);
            	max-width: 50%;
            	padding: 25px;
                margin: 0 13px 25px 13px;
                background: #fff;
                .pull-left{
                	margin-right: 25px;
                	.media-object{
                		max-width: 152px;
                	}
                }
            }
	}
	.media-heading{
		font-weight: 600;
		font-size: 20px;
		color: #000;
		margin-bottom: 10px;
	}
	.descr{
		font-size: 13px;
		font-weight: 400;
		color: #000;
	}
	.product-list_{
		.product-layout.product-list{
			margin-bottom: 30px;
			background: #fff;
			.image{
				margin-right: 30px;
			}
            &:after{
            	content: ' ';
            	display: block;
            	clear: both;
            }
            .caption_wr{
            	padding: 30px 30px 30px 0;
            }
            .category-name{
            	a{
	            	color: $blue;
	            	font-size: 13px;
	            	text-transform: uppercase;
	            	font-weight: 600;
	            }
            } 
            .article-date{
            	font-size: 13px;
            	color: #000;
            	font-weight: 500;
            }
            .title_c{
            	margin: 15px 0;
            	a{
	            	font-size: 21px;
	            	font-weight: 600;
	            	color: #000;
	            	line-height: 30px;
	            	text-transform: uppercase;
	            	text-decoration: none;
	            	transition:all .3s ease-in;
	            	&:hover{
	            		color: $color1;
	            	}
	            }
            } 
            .description{
            	font-size: 15px;
            	color: #000;
            	font-weight: 400;
            }
		}
		.btn_blog{
			margin-top: 20px;
			button{
				font-size: 15px;
				font-weight: 500;
				border: none;
				background: no-repeat;
				color: $color1;
				transition:all .3s ease;
				&:hover{
					color: #bc360a;
				}
			}
		}
	}
	#new_product_1{
       .btn_sp button + button{
       	display: none;
       }
	}
	a.load_more {
	    font-size: 17px;
	    font-weight: 500;
	    color: $blue;
	    width: 100%;
	    max-width: 310px;
	    border-radius: 0;
	    border: 3px solid $blue;
	    padding: 10px 15px;
	    transition: all .3s ease-in;
	    background: url(/catalog/view/theme/default/img/refresh-button.svg) no-repeat;
	    background-size: 25px;
	    background-position: 20% center;
	    &:hover {
		    background: $blue1 url(/catalog/view/theme/default/img/refresh-button_1.svg) no-repeat;
		    background-size: 25px;
		    background-position: 20% center;
		    color: #fff;
		}
	}
    .welcome{
    	padding: 0 25px;
    }
}
.blog_latest.categ_article{
	.wr_art_h{
		min-height: 240px;
		width: 100%;
		background: url(/catalog/view/theme/default/img/back_art.jpg ) no-repeat;
		display: flex;
		align-items:center;
		justify-content: center;
		text-align: center;
		h1{
			font-size: 26px;
		 line-height: 1.5;
		 font-weight: 600;
		 color: #fff;
		 max-width: 500px;
		 padding: 0 10px;
		}
	}
    .wr_artic{
    	width: 100%;
    	.tab-content{
    		background: #fff;
    		padding: 30px 35px;
    		.category-name a{
    			color: $blue;
    			font-size: 19px;
    			font-weight: 500;
    			text-transform: uppercase;
    		}
    		.article-date{
    			font-size: 19px;
    			font-weight: 400;
    			color: #000; 
    		}
    		#description{
    			p{
    				margin-top: 50px;
    				font-size: 18px;
    				line-height: 1.5;
    				color: #000;
    				font-weight: 400;
    				margin: 20px 0;
    			}
            .title_welcome{
            	font-weight: 700;
            }
    		}
    	}
    }
}
// blog-end=-=-=-

// conact=-=-=

.contact{
	.breadcrumb{
	 border-radius: 0;
	 font-size: 17px;
	 text-transform: uppercase;
	 background: none;
	 padding: 8px 0;
	 margin: 35px 0;
	 li a{
	 	color: #000;
	 }
	 li:nth-last-of-type(1) a{
	 	font-weight: 600;
	 }
	 li+li:before {
	     color: #000;
	     content: ">";
	 }
	}
	h1,.title_map{
	 font-size: 21px;
	 line-height: 1.5;
	 font-weight: 600;
	 margin-bottom: 40px;
	}
	.title_map{
		margin-bottom: 0;
	}
	.nav.nav-tabs{
        text-align: center;
        font-size: 0;
        margin-bottom: 40px;
        margin-top: 40px;
        border-bottom: none;
        li{
        	display: inline-block;
            float: none;
        	a{
        		font-size: 18px;
        		line-height: 1.5;
        		border: 1px solid #cacaca;
        		margin: 0;
        		border-radius: 0;
        	}
        }
        li.active a{
        	background: $blue1;
        	color: #fff;
        	border: 1px solid $blue1;
        }
        &+.tab-content{
        	border-top: 1px solid #ddd;
        }
	}
	.store{
		position: relative; width: 100%;
	}
	.store-block{
		display: inline-block; left: 3em; position: absolute; top: 5em; max-width: 280px
	}
	.store-contact{
		background-color: $blue1; color: white; padding: 3em;
		a{
			color: #fff;
		}
	}
	.store-map{
		height: 555px; width: 100%;
	}
	.store-image{
		width: 100%;
	}
	.btn_contact{
		position: absolute;
		width: 30px;
		height: 30px;
		top: 0;
		padding: 7px;
		background: $blue1;
		border-radius: 50%;
		display: none;
		svg{
			fill:#fff;
		}
	}
	.welcome{
		padding: 0;
	}
}

.welcome{
	clear: both;
}
// end_contact=-=-

.information{
	.breadcrumb{
	 border-radius: 0;
	 font-size: 17px;
	 text-transform: uppercase;
	 background: none;
	 padding: 8px 0;
	 margin: 35px 0;
	 li a{
	 	color: #000;
	 }
	 li:nth-last-of-type(1) a{
	 	font-weight: 600;
	 }
	 li+li:before {
	     color: #000;
	     content: ">";
	 }
	}
}
.lk{
	.breadcrumb{
	 border-radius: 0;
	 font-size: 17px;
	 text-transform: uppercase;
	 background: none;
	 padding: 8px 0;
	 margin: 35px 0;
	 margin-bottom: 10px;
		 li a{
		 	color: #000;
		 }
		 li:nth-last-of-type(1) a{
		 	font-weight: 600;
		 }
		 li+li:before {
		     color: #000;
		     content: ">";
		 }
	}
	.well{
        border-radius: 0;
	    background-color: #ffffff;
	    // border: 1px solid $blue;
	    margin-top: 20px;
	}
	.btn.btn-primary{
		font-size: 14px;
		text-transform: uppercase;
		width: 100%;
		max-width: 200px;
		padding: 10px 15px;
		background: $color1;
		border: 2px solid $color1;
		color: #fff;
		cursor: pointer;
		transition: all .5s ease;
		position: relative;
		z-index: 1;
		border-radius: 0;
		&:before {
		    content: ' ';
		    position: absolute;
		    width: 100%;
		    left: 0;
		    right: 0;
		    background: #fff;
		    height: 0;
		    top: 0;
		    z-index: -1;
		    transition:all 0.3s ease;
		}
		&:hover{
			color: $color1;
		}
		&:hover:before{
			height: 100%;
		}
	}

	td{
		color: #000;
	}
	.btn.btn-info,.btn.btn-danger{
		border-radius: 0;
		padding: 6px 12px;
		transition: all .5s ease;
		position: relative;
		z-index: 1;
		&:before {
		    content: ' ';
		    position: absolute;
		    width: 100%;
		    left: 0;
		    right: 0;
		    background: #fff;
		    height: 0;
		    top: 0;
		    z-index: -1;
		    transition:all 0.3s ease;
		}
		&:hover{
			color: #d9534f;
		}
		&:hover:before{
			height: 100%;
		}
	}
	.btn.btn-default{
       border-radius: 0;
       transition: all .5s ease-in;
       padding: 9px 15px;
	}
	.buttons{
		margin-bottom: 20px;
		margin-top: 15px;
		&:after{
			content: ' ';
			display: block;
			clear: both;
		}
	}
	#column-right{
		padding-right: 0;
		.list-group{
			a{
				border-top-left-radius: 0;
				    border-top-right-radius: 0;
				transition: all .3s ease-in;
				&:hover{
                    background: #337ab7;
                    color: #fff;
				}
			}
		}
	}
	.form-control{
       border-radius: 0;
	}
	#content{
		border:1px solid #2e6da4;
		background: #fff;
	}
	.lk_wr,#content{
		margin-bottom: 20px;
		&:after{
			content: ' ';
			display: block;
			clear: both;
		}
	}
	a.list-group-item, button.list-group-item {
	    color: #000;
	}
	.list-unstyled{
		li a {
			color: #000;
			font-size: 14px;
			line-height: 1.4;
			transition:all .3s ease-in;
			&:hover{
				color:$color1;
				text-decoration: none;
			}
		}
	}
	.row{
		margin-left: 0;
		margin-right: 0;
	}
}
// -=-=-
.search{
	.search_wr{
		&:after{
			content: ' ';
			clear: both;
			display: block;
		}
		.form-control{
			border-radius: 0;
			margin-bottom: 5px;
		}
	}
	h1{
		margin: 0;
		margin-bottom: 20px;
	}
}
.block_p.search{
    	.btn.btn-primary{
    		border: none;
    		font-size: 14px;
    		text-transform: uppercase;
    		width: 100%;
    		max-width: 200px;
    		padding: 10px 15px;
    		background: $color1;
    		color: #fff;
    		cursor: pointer;
    		transition: all .5s ease;
    		position: relative;
    		z-index: 1;
    		border-radius: 0;
    		&:before {
			    content: ' ';
			    position: absolute;
			    width: 100%;
			    left: 0;
			    right: 0;
			    background: $blue;
			    height: 0;
			    top: 0;
			    z-index: -1;
			    transition:all 0.3s ease;
			}
			&:hover:before{
				height: 100%;
			}
    	}

		h1{
			margin: 0;
			margin-bottom: 20px;
		}
	.search_wr_{
		background: #fff;
		padding: 20px 30px;
		p{
			margin: 5px 0;
			[type="checkbox"]{
				display: none;
			}
			label.checkbox-inline:after {
			    content: ' ';
			    display: block;
			    width: 15px;
			    height: 15px;
			    background: #fff;
			    border: 1px solid #d1d1d1;
			    position: absolute;
			    left: 0px;
			    top: calc(50% - 7.5px);
			}
			[type="checkbox"]:checked + label:after{
				content: ' ';
			    background: $blue1 url(/catalog/view/theme/default/img/arr.png) no-repeat center;
			    border: 1px solid $blue;
			    background-size: 9px;
			    position: absolute;
			}
			[type="checkbox"]:disabled + label{
				color: #ccc;
			}
		}
	}
	.btn_search{
		text-align: center;
		margin-top: 20px;
	}
}

.logo_fix_hide{
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 40px;
	z-index: 10;
}
.files{
	border: none;
}
table {
    margin: 0 auto 1.5em;
    width: 75%;
}
.sticky-wrap {
    overflow-x: auto;
    position: relative;
    margin-bottom: 1.5em;
    width: 100%;
}
.sticky-wrap .sticky-thead,
.sticky-wrap .sticky-col,
.sticky-wrap .sticky-intersect {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .125s ease-in-out;
    z-index: 50;
    width: auto;
}
.sticky-wrap .sticky-thead {
    box-shadow: 0 0.25em 0.1em -0.1em rgba(0,0,0,.125);
    z-index: 100;
    width: 100%;
}
.sticky-wrap .sticky-intersect {
    opacity: 1;
    z-index: 150;
}
.sticky-wrap .sticky-intersect th {
    background-color: #666;
    color: #eee;
}
.sticky-wrap td,
.sticky-wrap th {
    box-sizing: border-box;
}
.wr_price{
	border: none;
	display: inline-block;
	margin-top: 15px;
	.time{
		margin-top: 10px;
		min-width: 175px;
	.countdown.is-countdown{
       color: #e84814;
       font-size: 26px;
       font-weight: 600;
       line-height: 1.2;
       background: none;
       border: none;
		}
		.countdown{
			&>span{
				display: flex;
				justify-content: center;
			}
			.countdown-section{
				position: relative;
				border-right: none;
				display: inline-block;
				font-size: 26px;
				padding-right: 15px;
				width: initial;
                span{
                	font-size: 26px;
                	display: inline-block;
                }
				&:after{
					content: ':';
					position: absolute;
					right: 5px;
					top: 0;
				}
				&:nth-last-of-type(1):after{
					display: none;
				}
				span+span{
					display: none;
				}
			} 
		}
		& + p{
			color: #3b3b3b;
			font-size: 15px;
			line-height: 1.2;
			font-weight: 400;
			text-align: center;
		}
	}

	.old_price{
		font-size: 27px;
		color: #e84814;
		font-weight: 500;
		text-align: center;
		span{
			font-size: 14px;
	    padding-left: 5px;
	    position: relative;
	    display: inline-block;
	    top: -9px;
		}
	}
	.special_price{
		font-size: 40px;
		color: #000;
		font-weight: 700;
		text-align: center;
		margin-top: 15px;
		span{
			font-size: 21px;
		    padding-left: 5px;
		    display: inline-block;
		    position: relative;
		    top: -13px;
		}
	}
	.wr_price_text{
		font-size: 15px;
		color: #000;
		font-weight: 400;
	}
}
	.sticky-wrap{
		overflow: auto;
		table {
	    min-width: 768px;
	}
}
#content{
	margin-bottom: 10px;
}
.cont.error{
	#content{
		background: #fff;
		text-align: center;
	    padding: 20px 15px;
	    box-sizing: border-box;
	    h1{
	    	margin: 0;
	    }
	    img{
	    	max-width: 300px;
	    }
	    p{
	    	margin-bottom: 15px;
	    	font-size: 16px;
	    	font-style: italic;
	    }
	    .search{
	    	label{
	    		display: flex;
	    		margin: 0 auto;
	    		max-width: 500px;
	    		border:1px solid $blue;
	    		input{
	    			width: calc(100% - 32px);
	    			border: none;
	    			box-sizing: border-box;
	    			padding: 5px 15px;
	    			font-weight: 400;
	    		}
	    		button{
	    			background: $blue;
				    border: none;
				    width: 50px;
				    height: 35px;
				    box-sizing: border-box;
	    		}
	    	}
	    }
	}
	.breadcrumb{
	 border-radius: 0;
	 font-size: 17px;
	 text-transform: uppercase;
	 background: none;
	 padding: 8px 0;
	 li a{
	 	color: #000;
	 }
	 li:nth-last-of-type(1) a{
	 	font-weight: 600;
	 }
	 li+li:before {
	     color: #000;
	     content: ">";
	 }
	}
}
.product-compare{
	.breadcrumb{
	 border-radius: 0;
	 font-size: 17px;
	 text-transform: uppercase;
	 background: none;
	 padding: 8px 0;
	 margin-top: 10px;
	 margin-bottom: 10px;
	 li a{
	 	color: #000;
	 }
	 li:nth-last-of-type(1) a{
	 	font-weight: 600;
	 }
	 li+li:before {
	     color: #000;
	     content: ">";
	 }
	}
	h1{
		margin: 15px 0;
		font-size: 24px;
	}
	tbody tr td:nth-of-type(1){
		min-width: 150px;
	}
	.rating{
		i{
			font-size: 16px;
		}
		.fa.full_star{
			color: #ffbb05;
		}
		.fa.empty_star{
			color: #ccc;
		}
		.count_star{
			margin-left: 10px;
			display: inline-block;
		}
	}
	.btn_compare{
	    font-size: 14px;
	    text-transform: uppercase;
	    width: calc(100% - 50px);
	    max-width: 200px;
	    padding: 8px 15px;
	    background: #e84814;
	    border: 1px solid #e84814;
	    color: #fff;
	    cursor: pointer;
	    transition: all .5s ease-in;
	    border-radius: 0;
	    &:hover{
           background: #393939;
	    }
	    & + button{
          display: none;
	    }
	}
	.btn_del{
		display: inline-block;
	    padding: 6px 12px;
	    margin-bottom: 0;
	    font-size: 14px;
	    font-weight: 400;
	    line-height: 1.5;
	    text-align: center;
	    white-space: nowrap;
	    vertical-align: middle;
	    -ms-touch-action: manipulation;
	    touch-action: manipulation;
	    cursor: pointer;
	    user-select: none;
	    background-image: none;
	    border: 1px solid transparent;
	    color: #fff;
	    background-color: #d9534f;
	    border-color: #d43f3a;
	    border-radius: 0;
	    &:hover{
	    	color: #fff;
	    	background-color: #c9302c;
	    	border-color: #ac2925;
	    }
	}
	.table_wr{
		overflow: auto;
		table{
			min-width: 768px;
		}
	}
	.compare_empty{
		background: #fff;
		padding: 20px 15px;
		p{
			font-size: 16px;
		}
		&:after{
			content: ' ';
			display: block;
			clear: both;
		}

    	.btn.btn-default{
    		border: none;
    		font-size: 14px;
    		text-transform: uppercase;
    		width: 100%;
    		max-width: 200px;
    		padding: 10px 15px;
    		background: $color1;
    		color: #fff;
    		cursor: pointer;
    		transition: all .5s ease;
    		position: relative;
    		z-index: 1;
    		border-radius: 0;
    		&:before {
			    content: ' ';
			    position: absolute;
			    width: 100%;
			    left: 0;
			    right: 0;
			    background: $blue;
			    height: 0;
			    top: 0;
			    z-index: -1;
			    transition:all 0.3s ease;
			}
			&:hover:before{
				height: 100%;
			}
    	}
	}
}
.account-wishlist{
	.btn.btn-primary{
		width: inherit;
		padding: 5px 10px;
       &+ button{
       	display: none;
       }
	}
	.table{
		min-width: 768px;
		&>tbody>tr>td{
		vertical-align: middle;
	}
	} 
}

.tabs_item {
    display: none;
    &:first-child {display: block;}
}
.manuf_list{
	.breadcrumb{
		margin-bottom: 10px;
	}
	h1{
		margin: 10px 0;
		text-align: center;
		& + p{
			font-size: 14px;
			a{
				font-size: 16px;
				color: $color1;
				transition:all .3s ease;
				&:hover{
					color: $blue1;
				}
			}
		}
	}
	a{
		font-size: 16px;
		color: $color1;
		transition:all .3s ease;
		&:hover{
			color: $blue1;
		}
	}
	h2{
		border-bottom: 1px solid #ccc;
		padding-bottom: 10px;
	}
	#mfilter-content-container {
	    background: #fff;
	    padding: 10px 15px;
	    box-sizing: border-box;
	}
}
















// ada=-=-=-

@media (max-width: 1200px){
	#price_click {
	    // max-width: 100px;
	    a{
	    	font-size: 12px;
	    }
	    .price_click_drop a{
	    	font-size: 12px;
	    }
	}
	#block_menu{
		#search {
			flex-shrink: 5;
		}
		#menu {
		    max-width: 753px;
		    flex-shrink: 2;
		    width: 100%;
		}
	} 
	.category .wr_limit_sort{
		padding: 10px 15px;
		.sort_pro {
		       font-size: 14px;
		   }
		   .count_product {
		       font-size: 14px;
		   }
		   .limit_pro {
		       font-size: 14px;
		   }
	} 
	.blog_latest,.information,.contact,.lk{
		padding: 0 15px;
	}
    .product_wr .left .image_main {
        width: 100%;
        max-width: 450px;
        padding: 0 15px;
    }
    .product_wr{
    	padding: 0 15px;
    	.right {
    	        padding: 40px 25px;
    	    }
    } 
    .product_wr .review_wr .wr_tab .nav-tabs,.compare {
        padding: 0 15px;
    }
    .product_wr .review_wr .tab-content .tab-pane {
    padding: 0 15px;
}
.product_wr .review_wr .review_wr_{
	padding: 25px 15px;
}
}
@media(max-width: 992px){
	.category{
		padding: 0 15px;
	}
	#block_menu #menu .menu_fix_child{
	    display: inline-block;
	}
   #new_blog .new_blog_wr .blog_img img {
       min-width: 150px;
   }
   #wrapper{
   	.header-top{
   		padding: 9px 15px;
   	}
   	.header-top .right_block_header .wr_bl{
   	   	p {
   	   	    font-size: 12px;
   	   	}
   	   	.wish_and_comp a:nth-of-type(1) {
   	   	    padding-right: 20px;
   	   	}
   	   	#cart {
   	   	    padding-left: 20px;
   	   	    padding-right: 20px;
   	   	}
   	   	.login_c {
   	   	    padding-left: 0;
   	   	}
   	   	a div {
   	   	    min-height: inherit;
   	   	    width: 22px;
   	   	    height: 22px;
   	   	    vertical-align: bottom;
   	   	    .icon_count{
   	   	    	top: -4px;
   	   	    }
   	   	}
   	   }
   }  
/*    .hamburger-btn{
	display: inline-block;
} */
   .login_c {
       padding-left: 0;
       p{
       	display: block !important;
       }
       // display: none;
   }
   .menu_fix_child:not(.menu_fix){
   	display: inline-block;
   	padding-right: 32px;
   	background: url(/catalog/view/theme/default/img/menu_fix.png) no-repeat;
   	background-position: 100%;
   	cursor: pointer;
   	transition: all .3s ease-in;
   	font-size: 20px;
   	font-weight: 600;
   	color: #fff;
   }

   #block_menu #menu .menu {
   	padding: 10px 5px 10px 15px !important;
       position: absolute;
       top: 60px;
       padding: 0;
       display: none;
       background: $blue;
       width: 100%;
       max-width: 250px;
       a{
       	width: 100%;
       	padding: 5px 0 !important;
       	img{
         display: none;
       	}
       }
       .arrow_menu_foxid{
       	  width: 30px;
   	      height: 20px;
   	      position: absolute;
   	      top: 0;
   	      right: 0;
   	      text-align: center;
   	      display: -webkit-box;
   	      display: -ms-flexbox;
   	      display: flex;
   	      -webkit-box-align: center;
   	      -ms-flex-align: center;
   	      align-items: center;
   	      -webkit-box-pack: end;
   	      -ms-flex-pack: end;
   	      justify-content: flex-end;
   	      z-index: 10;
   	      img{
   	      	transform: rotate(-90deg);
   	      	display: inline-block;
   	      	transition: all .2s ease;
   	      }
       }
       .arrow_menu_foxid.act img{
       	transform: rotate(0);
       }
   }
   #block_menu #menu .menu li:hover{
   	background: transparent;
   	box-shadow: none;
   	.menu_drop {
   	       display: none;
   	       background: transparent;
   	       position: static !important;
   	       box-shadow: none;
   	       li:hover{
   	       	background: none;
   	       	a{
   	       		color: $color1;
   	       	}
   	       }
   	   }
   } 
   #block_menu #menu .menu li .menu_drop {
       position: static;
       background: transparent;
       box-shadow: none;
       ul{
       	padding-left: 10px;
       	li{
       		padding: 0;
       	}
       }
   }
   #block_menu #search {
       flex-shrink: 3;
   }
   .menu_btn{
	   	max-width: 250px;
	   	width: 100%;
	   	text-align: center;
	   	height: 58px;
	   	display: flex;
	   	align-items:center;
	   	justify-content: center;
	   	cursor: pointer;
   }
   .advantages{
       .advantages_child{
       	width: 50%;
       }
   }
   #header_fix .wr_bl{
   	padding: 0;
   	padding-left: 10px;
   	#cart {
   	       padding-left: 10px;
   	       padding-right: 0;
   	   }
   	   .wish_and_comp a:nth-of-type(1) {
   	       padding-right: 10px;
   	   }
   	   p{
   	   	font-size: 12px;
   	   }
   } 
   .welcome {
       padding: 0 15px;
   }
   .container.wrap_footer{
   	  width: 100% !important;
   	  .wrapper_link{
   	  	justify-content: space-around;
   	  	.wr_foot{
   	  		width: calc(40% - 10px);
   	  		.footer_title{
   	  			margin-bottom: 10px;
   	  		}
   	  	}
   	  	.wr_foot:nth-of-type(2) ~ .wr_foot{
   	  		margin-top: 20px;
   	  	}
   	  }
   }
   .advantages.padd{
      margin: 0 -15px;
   }
   #callback_1{
    width: 150px;
    height: 30px;
    right: -60px;
    top: 30%;
    font-size: 14px;
   	span{
   		margin-left: 5px;
   	}
   }
   #column-left{
   	position: fixed;
   	left: -277px;
   	transition: all .3s ease-in;
   	top: 30%;
   	max-height: 300px;
   	overflow: auto;
   	background: #fff;
   	z-index: 10;
   }
   #column-left.act{
     left: 0;
   }
   .category #content.categ {
       width: 100%;
       padding-left: 0;
   }
   #btn_filter{
       display: block;
   }
   #list-view{
   	display: none;
   }
   .category .media {
       min-height: 140px;
       padding: 15px;
       margin-bottom: 10px;
   }
   .category h1 {
       font-size: 16px;
       margin: 20px 0 15px 0;
   }
   .category a.load_more{
   	font-size: 14px;
    max-width: 250px;
   }
   .blog_latest{
   	.showcase-list {
   	       width: 100%;
   	       float: none;
   	   }
   	   .breadcrumb {
   	       margin: 15px 0;
           font-size: 14px;
           line-height: 1.3;
   	   }
   	   #blog_popular .new_blog_wr .blog_img img {
   	       min-width: 100px;
   	   }
	   	   .showcase-list_categ {
		    margin: 0;

		}
		.product-list_{
			padding: 0 15px;
		}
   } 
   .wr_artic{
   	padding: 0;
   }
   .contact{
   	h1,.title_map{
   	 margin-bottom: 40px;
   	}
	   .breadcrumb {
	       margin: 15px 0;
        font-size: 14px;
        line-height: 1.3;
	   }
   	.tabs_nav{
           margin-bottom: 20px;
           margin-top: 20px;
           li{
       	a{
       		font-size: 14px;
       	}
       }
   	}
   	.store-block{
   		display: inline-block; 
   		left: 3em; 
   		position: absolute; 
   		top: 5em; 
   		max-width: 280px;
   	}
   	.contact .store-contact {
   	    background-color: $blue1;
   	    color: #fff;
   	    padding: 15px 20px;
   	}
   	.store-map{
   		height: 555px;
   		 width: 100%;
   	}
   	.store-image{
   		width: 100%;
   	}
   }
   .information,.lk{
   		   .breadcrumb {
   		       margin: 15px 0;
   	        font-size: 14px;
   	        line-height: 1.3;
   		   }
   }
   .product_wr{
   	.product_wrapper {
   	       display: flex;
   	       flex-direction: column;
   	       width: 100%;
   	       .left,.right{
   	       	width: 100%;
   	       	.image_main{
   	       		margin: 0 auto;
   	       		padding: 0;
   	       	}
   	       }
   	   }
   	   .breadcrumb {
   	          border-radius: 0;
   	          font-size: 14px;
   	          text-transform: uppercase;
   	          background: 0 0;
   	          padding: 8px 0;
   	          margin: 15px 0;
   	      }
   	     .right{
   	     	padding: 20px 15px;
   	     }
   } 
   .product_wr .review_wr .tab-content .block_review {
       flex-direction: column;
       .autor_wr {
           max-width: inherit;
       }
       .descr {
           width: 100%;
       }
   }
   .cont.error{
   	#content{

   	    h1{
   	    	font-size: 18px;
   	    }
   	    p{
   	    	font-size: 14px;
   	    }
   	}
   	.breadcrumb{
   	 font-size: 14px;
   	 padding: 8px 15px;
   	}
   }
   .product_wr .review_wr .wr_tab .tabs_nav li a {
       padding: 5px 10px;
       display: inline-block;
       font-size: 14px;
   }
}

@media(max-width: 768px){
   .telephones{
   	display: none;
   }
   #logo{
   	max-width: 100px;
   	margin-right: 30px;
   }
   #wrapper .header-top .right_block_header .wr_bl{
   	p {
   	    font-size: 11px;
   	}
   	.wish_and_comp a:nth-of-type(1) {
   	    padding-right: 5px;
   	}
   	#cart {
   	    padding-left: 5px;
   	    padding-right: 5px;
   	}

   }
   .search_cl{
	   	display: flex;
	    align-items: center;
	    width: 40px;
	    cursor: pointer;
	    justify-content: center;
   }
   .search_cl_1{
   	   	display: flex;
	    align-items: center;
	    width: 40px;
	    cursor: pointer;
	    justify-content: center;
   }
   #block_menu #search{
   	display: none;
   	position: absolute;
   	    width: 100%;
   	    max-width: inherit;
   	    bottom: -45px;
   	    padding: 10px 15px;
   	    .live-search {
   	        top: 44px;
   	    }
   }
   #header_fix #search1{
	   	display: none;
	   	position: absolute;
	    width: 100%;
	    max-width: inherit;
	    bottom: -45px;
	    padding: 10px 15px;
	    .live-search {
	        top: 44px;
	    }
   }
   .block_p{
   	padding: 0 15px;
   	margin-top: 20px;
   	&>.title{
	    font-size: 14px;
	    margin-bottom: 10px;
	    padding-bottom: 15px;
	    padding-right: 30px;
	    .swiper-button-prev {
	           right: 30px;
	       }
   	}

   }
   .welcome {
       margin-bottom: 35px;
   }
   .banner_1, .banner_second {
       margin-top: 30px!important;
   }
   .manufacturer{
   	margin-top: 10px;
   	margin-bottom: 30px;
   	.swiper-container {
   	    padding: 30px 0;
   	}
   	.title {
   	       font-size: 16px;
   	       padding-top: 20px;
   	   }
   } 
   .container.wrap_footer > .wr_foot{
        width: 25%;
   }
   .container.wrap_footer .wrapper_link .wr_foot {
       width: calc(50% - 10px);
   }
   .category #column-left .list-group .list-group-item {
       font-size: 14px;
   }
   .category .wr_special_block .special_block {
       margin: 0 10px;
       background: #fff;
       width: calc(50% - 20px);
       margin-bottom: 30px;
    	.title{
    	  		min-height: 38px;
    	  		a {
    		   	    font-size: 14px;
    		   	}
    	  	}
    	  	.sku {
   		  	    font-size: 14px;
   		  }
   }
   .category #column-left{
   	.list-group .list-group-item {
   	       font-size: 14px;
   	   }
   } 
   .category a.load_more{
       background: url(/catalog/view/theme/default/img/refresh-button.svg) no-repeat;
           background-size: 20px;
           background-position: 20% center;
       &:hover{
       	background:$blue1 url(/catalog/view/theme/default/img/refresh-button_1.svg) no-repeat;
   	    background-size: 20px;
   	    background-position: 20% center;
       	color: #fff;
       }
   }
   .blog_latest a.load_more{
       background: url(/catalog/view/theme/default/img/refresh-button.svg) no-repeat;
           background-size: 20px;
           background-position: 20% center;
           	font-size: 14px;
            max-width: 250px;
       &:hover{
       	background:$blue1 url(/catalog/view/theme/default/img/refresh-button_1.svg) no-repeat;
   	    background-size: 20px;
   	    background-position: 20% center;
       	color: #fff;
       }
   }
      .blog_latest{
   		.showcase-list_categ .showcase-list_categ_child {
   		    width: 100%;
   		    max-width: inherit;
   		    padding: 15px;
   		    margin: 0;
   		    margin-bottom: 20px;
   		}
      } 
      .blog_latest.categ_article{
      	.wr_art_h{
      		min-height: 120px;
      		h1{
      			font-size: 18px;
      		}
      	}
          .wr_artic{
          	.tab-content{
          		padding: 10px 15px;
          		.category-name a{
          			font-size: 16px;
          		}
          		.article-date{
          			font-size: 16px;
          		}
          		#description{
          			p{
          				font-size: 14px;
          			}
          		}
          	}
          }
      }
      .contact .store-block {
          left: 5px;
          max-width: 270px;
      }
      .contact .nav.nav-tabs li {
          margin-bottom: 2px;
          a{
          	font-size: 12px;
          	padding: 5px 10px;
          }
      }
    .gmnoprint{
    	display: none;
    }
     	.contact .store-block {
     	    left: -300px;
     	    transition:all .3s ease-in;
     	    &.act{
     	    	left: 5px;
     	    }
     	}
     	.contact .btn_contact{
     		top: 15px;
     		left: 15px;
     		display: block;
     		svg{
     			fill:#fff;
     		}
     	}
     	.contact .store-map {
     	    height: 480px;
     	}
     	.product_wr .review_wr .tab-content .form-review_top .rev_pr_wr {
     	    flex-direction: column;
     	}
     	.product_wr .review_wr .wr_tab .nav-tabs li a {
     	    font-size: 14px;
     	}
     	.product_wr .review_wr .tab-content .form-review_top .rev_pr_wr .rev_pr {
     	    font-size: 14px;
     	    line-height: 1.5;
     	    margin-right: 20px;
     	}
     	.product_wr .review_wr .tab-content .form-review_top .btn_popup {
     	    padding: 7px 15px;
     	    font-size: 13px;
     	}
     	.product_wr .review_wr .tab-content .form-review_top {
     	    margin-bottom: 30px;
     	    flex-direction: column;
     	    align-items: center;
     	    justify-content: center;
     	    .rating{
     	    	margin: 10px 0;
     	    }
     	    .rev_pr{
     	    	margin-right: 0;
     	    	text-align: center;
     	    }
     	}
     	.product_wr .left {
     	    padding: 20px 0;
     	    display: block;
     	    vertical-align: top;
     	    padding: 0 15px;
     	}
     	.product_wr{
     		padding: 0 10px;
     		.right{
	     		.manuf_wr {
	     		    margin-bottom: 15px;
	     		    margin-top: 15px;
	     		    flex-direction: column;
	     		    & > div{
	     		    	width: 100%;
	     		    	margin: 5px 0;
	     		    }
	     		}
	     		h1 {
	 		     	    font-size: 21px;
	 		     	}
	     	} 
     	} 
     	.product_wr .review_wr .tab-content .modal-content {
     	    padding: 10px;
     	}
     	.product_wr .review_wr .tab-content .star {
     	    margin: 10px 0;
     	    flex-direction: column;
     	    align-items:flex-start;
     	    .rating_w{
     	    	margin-top: 0;
     	    	margin-bottom: 10px;
     	    }
     	}
}
@media(max-width: 640px){
	.product_wr .right{
	     .wr_price_prod {
	         flex-direction: column;
	         align-items: center;
	         margin-bottom: 10px;
	     }
	     .wish_comp_bottom_wr {
	         flex-direction: column;
	     }
	} 
	.container.wrap_footer .wrapper_link{
	.wr_foot {
		    width: calc(70% - 10px);
		}
		.wr_foot:nth-of-type(2)~.wr_foot {
			    margin-top: 0;
			}   
	}
	.footer_title{
		position: relative;
		.btn_foot{
          display: inline-block;
          position: absolute;
          right: 0;
          cursor: pointer;
		}
	} 
	.hide_foot{
		display: none;
		margin-bottom: 10px !important;
	}
	footer .wrap_footer {
	    align-items: flex-start;
	}
	.container.wrap_footer>.wr_foot {
	    width: 40%;
	}
	.category .wr_limit_sort .count_product {
	   display: none;
	}
	.blog_latest .product-list_ .product-layout.product-list .caption_wr {
	    padding:5px 15px;
	}
	.blog_latest .product-list_ .product-layout.product-list .title_c a {
	    font-size: 18px;
	}
	.blog_latest .product-list_ .product-layout.product-list .image {
	    margin-right: 0;
	    float: none !important;
	    text-align: center;
	    img{
	    	margin: 0 auto;
	    	padding-top: 5px;
	    }
	}
	.blog_latest .product-list_ {
	    padding: 0;
	}
}
@media(max-width: 540px){
	.advantages{
	    .advantages_child{
	    	width: 100%;
	    	padding-left: 20px;
	    	padding-top: 15px;
	    	min-height: 75px;
	    }
	}
	#header_fix .wr_bl{
		padding: 0;
		padding-left: 10px;
	   p{
	   	display: none;
	   }
	   #cart{
	   	width: 28px;
	   	margin-top: 8px;
	   }
	   .wish_and_comp{
	   	width: 50px;
	   	margin-top: 8px;
	   	a:nth-of-type(1) {
	   	    padding-right: 15px;
	   	}
	   }
	}
	#price_click {
	    max-width: 85px;
	    margin-left: 5px;
	}
	#header_fix .menu_fix .menu_fix_drop {
	    width: 270px;
	}
	#blog_popular,#new_blog{
		 .new_blog_wr {
		    flex-wrap: wrap;
		    .blog_img{
		    	width: 100%;
		    }
		    .blog_desc .blog_btn {
		        margin-top: 0;
		    }
		}
	}
	 
	#subscribe{
		padding: 5px 10px;
		.subscribe {
		    flex-direction: column;
		    align-items: center;
		    padding-bottom: 10px;
		    .subscribe_title{
		    	margin-bottom: 5px;
		    }
		}
	} 
	.container.wrap_footer {
	    width: 100%!important;
	    flex-wrap:wrap;
	}
	.container.wrap_footer>.wr_foot {
	    width: 100%;
	    text-align: center;
	    order: 2;
	}
	footer{
		.wrap_footer{
			.wrapper_link{
				width: 100%;
				order: 1;
			}
			.powered_wr {
				    margin-top: 20px;
				}
		}
		.footer_bottom {
			    justify-content: center;
			}
	}
	.container.wrap_footer .wrapper_link .wr_foot {
	    width: calc(100% - 10px);
	}
	.category .wr_limit_sort {
	    flex-wrap: wrap;
	    .sort_pro {
	        width: 100%;
	        select{
	        	padding-right: 25px !important;
	        	max-width: 160px !important;
	        }
	    }
	    .limit_pro {
	        width: 100%;
	        margin-top: 10px;
	    }
	}
}
@media(max-width: 480px){
	.category .wr_special_block .special_block {
	    width: calc(90% - 20px);
	    margin: 0 auto;
	    margin-bottom: 20px;
	}
	.blog_latest .showcase-list_categ .showcase-list_categ_child .pull-left {
	    margin-right: 0;
	    width: 100%;
	    .media-object{
	    	margin: 0 auto;
	    }
	}
}
@media(max-width: 440px){
	#wrapper .header-top .right_block_header .wr_bl{
		#cart {
		    padding-left: 10px;
		    padding-right: 10px;
		}
		.wish_and_comp a:nth-of-type(1) {
			    padding-right: 15px;
			}
		div p{
			display: none;
		}
	} 
}

::-webkit-scrollbar-button {
    background-image: url();
    background-repeat: no-repeat;
    width: 10px;
    height: 0;
}

::-webkit-scrollbar-track {
    background-color: #ecedee;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: $color;
}

::-webkit-scrollbar-thumb:hover {
    background-color: $color;
}

::-webkit-resizer {
    background-image: url();
    background-repeat: no-repeat;
    width: 8px;
    height: 0;
}

::-webkit-scrollbar {
    width: 8px;
}